import {ReturnSummaryKeys} from 'src/constants/constants';
import {
  ReturnSummaryInputFieldProps,
  ReturnSummaryInputType,
} from './ReviewSummary.types';

export const emptyTaxReturnSumary: ReturnSummaryInputFieldProps[] = [
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.agi,
    value: null,
  },
  {
    type: ReturnSummaryInputType.DROPDOWN,
    key: null,
    options: [
      ReturnSummaryKeys.standard_deduction,
      ReturnSummaryKeys.itemized_deduction,
    ],
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.taxable_income,
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.income_tax,
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.self_employment_tax,
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.income_tax_withheld,
    value: null,
  },
  {
    type: ReturnSummaryInputType.DROPDOWN,
    key: null,
    options: [
      ReturnSummaryKeys.tax_due_amount,
      ReturnSummaryKeys.tax_refund_amount,
    ],
    value: null,
    valueMandatory: true,
  },
];

import React, {useCallback, useEffect, useState} from 'react';
import {Slide, Typography} from '@mui/material';
import {BROWER_LOCAL_STORAGE_KEYS, themmeColor} from 'src/constants/constants';
import CrossIcon from 'src/icons/CrossIcon';

interface DrawerProps {
  isOpen: boolean;
  direction?: 'up' | 'down' | 'left' | 'right';
  children?: any;
  width: number;
  height: any;
  backgroundColor?: string;
  title?: string;
  onClose?: () => void;
}

const Drawer = ({
  isOpen,
  children,
  direction = 'left',
  width,
  height,
  backgroundColor = themmeColor.white,
  title,
  onClose,
}: DrawerProps) => {
  const [drawerConfig, setDrawerConfig] = useState<{
    isResizing: boolean;
    finalWidth: number;
    prevClientX: number;
  }>({
    isResizing: false,
    finalWidth: localStorage.getItem(BROWER_LOCAL_STORAGE_KEYS.DRAWER_WIDTH)
      ? Number(localStorage.getItem(BROWER_LOCAL_STORAGE_KEYS.DRAWER_WIDTH))
      : width,
    prevClientX: 0,
  });

  const getPosition = () => {
    switch (direction) {
      case 'left':
        return {right: 0};
      case 'right':
        return {left: 0};
      case 'up':
        return {bottom: 0};
      case 'down':
        return {up: 0};
    }
  };

  const handleMousedown = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setDrawerConfig((prev) => ({
        ...prev,
        isResizing: true,
        prevClientX: e.clientX,
      }));
    },
    [],
  );

  const handleMousemove = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // we don't want to do anything if we aren't resizing.
      if (!drawerConfig.isResizing) {
        return;
      }

      let newWidth =
        document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      let minWidth = width;
      let maxWidth = 800;
      if (newWidth >= minWidth && newWidth <= maxWidth) {
        setDrawerConfig((prev) => ({
          ...prev,
          finalWidth: newWidth,
        }));
        localStorage.setItem(
          BROWER_LOCAL_STORAGE_KEYS.DRAWER_WIDTH,
          newWidth.toString(),
        );
      }
    },
    [drawerConfig.isResizing, width],
  );

  const handleMouseup = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setDrawerConfig((prev) => ({...prev, isResizing: false}));
    },
    [],
  );

  useEffect(() => {
    if (!isOpen) {
      setDrawerConfig((prev) => ({...prev, isResizing: false}));
    }
    return;
  }, [isOpen, width]);

  useEffect(() => {
    if (drawerConfig.isResizing && isOpen) {
      document.addEventListener('mousemove', handleMousemove);
      document.addEventListener('mouseup', handleMouseup);

      return () => {
        document.removeEventListener('mousemove', handleMousemove);
        document.removeEventListener('mouseup', handleMouseup);
      };
    }
  }, [handleMouseup, handleMousemove, drawerConfig.isResizing, isOpen]);

  return (
    <Slide direction={direction} in={isOpen} mountOnEnter unmountOnExit>
      <div
        style={{
          position: 'absolute',
          ...getPosition(),
          width: drawerConfig.finalWidth,
          height,
          backgroundColor,
          borderLeft: 'solid 1px black',
          overflowY: 'auto',
        }}>
        <div
          id="dragger"
          onMouseDown={handleMousedown}
          style={{
            width: '5px',
            cursor: 'ew-resize',
            padding: '4px 0 0',
            borderTop: '1px solid #ddd',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            zIndex: 100,
            backgroundColor: '#f4f7f9',
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: title || onClose ? 12 : 0,
          }}>
          {title && (
            <Typography style={{fontWeight: 600, fontSize: 20}}>
              {title}
            </Typography>
          )}
          {onClose && (
            <div onClick={onClose}>
              <CrossIcon />
            </div>
          )}
        </div>
        {children}
      </div>
    </Slide>
  );
};

export default Drawer;

import _ from 'lodash';
import {emptyTaxReturnSumary} from './ReviewSummary.constants';
import {
  ReturnSummaryInputFieldProps,
  ReturnSummaryInputType,
} from './ReviewSummary.types';

export const getEmptyTaxReturnSummary = () => {
  const _emptyTaxReturnSummary = _.cloneDeep(emptyTaxReturnSumary);
  return _emptyTaxReturnSummary;
};

export const mapBackendResponseOnEmptyForm = (taxReturnSummary: {
  [key: string]: any;
}) => {
  const emptyTaxReturnSumary = getEmptyTaxReturnSummary();
  Object.entries(taxReturnSummary).forEach(([key, value]: any) => {
    emptyTaxReturnSumary.forEach((field) => {
      if (
        field.type === ReturnSummaryInputType.TEXT_INPUT &&
        field.key === key
      ) {
        field.value = value;
      } else if (
        field.type === ReturnSummaryInputType.DROPDOWN &&
        field.options?.includes(key)
      ) {
        field.key = key;
        field.value = value;
      }
    });
  });
  return emptyTaxReturnSumary;
};

export const convertFrontendTaxFormToBackendTaxForm = (
  data: ReturnSummaryInputFieldProps[],
) => {
  const backendData: any = {};
  data.forEach((field) => {
    if (field.key === null) {
      return;
    }
    backendData[field.key] = field.value;
  });
  return backendData;
};

export const isValidTaxReturnForm = (data: ReturnSummaryInputFieldProps[]) => {
  return data.every((field) => {
    if (field.valueMandatory && field.value === null) {
      return false;
    }

    if (
      field.type === ReturnSummaryInputType.DROPDOWN &&
      !field.options.includes(field.key)
    ) {
      return false;
    }

    return true;
  });
};

import {SET_THREADS} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';

export enum ThreadStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}

export enum ReturnType {
  TAX_RETURNS = 'TAX_RETURNS',
}

enum ThreadMessageType {
  CHAT = 'CHAT',
}

export interface ThreadMessage {
  author_name: string;
  comment: string;
  created_at: Date;
  id: number;
  type: ThreadMessageType;
}

export interface Thread {
  author_name: string;
  comment: string;
  created_at: Date;
  id: number;
  last_message_at: Date;
  messages: ThreadMessage[];
  resolved_by_name: string;
  status: ThreadStatus;
  type: string;
  unread_messages: number[];
}

const initialState = {
  threads: [] as Thread[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const threads = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_THREADS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type ThreadsStateType = typeof initialState;

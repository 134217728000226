import React, {createContext} from 'react';
import {BACKEND_RESPONSE_KEYS} from '../CpaCenterList/components/cpaList.utils';

export interface UserInfo {
  id: number;
  fly_user_id: string | undefined;
  fly_user_name: string;
  email: string;
  [BACKEND_RESPONSE_KEYS.NEXT_ACTION_DATE]: string;
  [BACKEND_RESPONSE_KEYS.LAST_STATUS_CHANGE]: string;
  [BACKEND_RESPONSE_KEYS.NOTE]: string;
  [BACKEND_RESPONSE_KEYS.STATUS]: number;
  [BACKEND_RESPONSE_KEYS.EXTENSION_STATUS]: number | string;
  [BACKEND_RESPONSE_KEYS.CCH_CLIENT_ID]: string;
}

type UserInfoData = {
  userInfo: UserInfo
  fetchUserDetails: () => void;
};

const UserInfoContext = createContext({} as UserInfoData);

export default UserInfoContext;

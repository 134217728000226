import {SET_BANK_DETAILS} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';

export interface BankDetail {
  return_id: number;
  account_number: string;
  routing_number: string;
  account_type: string;
  phone_number: string;
  cch_sync_status: boolean;
}

const initialState = {
  bankDetails: [] as BankDetail[],
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const bankDetails = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_BANK_DETAILS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type BankDetailsStateType = typeof initialState;

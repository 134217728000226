import {ROUTES_PLACEHOLDERS} from 'src/constants/constants';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';

export const UserInfoRoute = {
  TaxProfile: 'tax-profile',
  DocumentsOCR: 'documents-ocr',
  TaxQueries: 'tax-queries',
  UserRES: 'review-esign',
  UserCCH: 'cch',
} as const;

export const APP_FLOWS = {
  InternalUtilities: '/InternalUtilities',
};

export const ReviewEsignRoute = {
  UploadReturn: 'upload-return',
  CpaReview: 'cpa-review',
  BankDetails: 'bank-details',
};

export const CpaCenterV2RouteParts = {
  CpaCenterV2Base: 'cpa-center-v2',
  UserId: ':userId',
  DocumentReview: 'document-review',
  DocumentId: ':docId',
  TaxProfileReview: 'tax-profile-review',
  DistributeDeductions: 'distribute-deductions',
  UserQueries: 'user-queries',
  QueryId: ':queryId',
  CCH: 'cch',
  ReviewEsign: 'review-esign',
  ReturnId: ':returnId',
  TaxReturnSection: ':taxReturnSection',
};

export const RouteName = {
  InternalUtilities: {
    CreateProfile: 'create_profile',
    WhiteListPromoCode: 'whitelist_promo_code',
    GetUserDetails: 'get_user_details',
  },
  ReactAdmin: '/',
  Login: '/login',
  MissingExpenses: `/missingexpenses/${ROUTES_PLACEHOLDERS.FLYFIN_USER_ID_PLACEHOLDER}`,
  TaxFiling: '/tax-filing',
  UserQueries: {
    UserQueries: '/user-queries',
    UserQuery: '/user-query/:flyfin_user_id/:query_id',
  },
  CpaCenterV2: `/${CpaCenterV2RouteParts.CpaCenterV2Base}`,
  BoiFilingCenter: `/boi-filing`,
};

export const CpaCenterV2Route = {
  CpaCenterV2List: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}`,
  CpaCenterV2User: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}`,
  CpaCenterV2DocumentReview: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.DocumentReview}`,
  CpaCenterV2DocumentViewer: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.DocumentReview}/${CpaCenterV2RouteParts.DocumentId}`,
  CpaCenterV2TaxProfileReview: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.TaxProfileReview}`,
  CpaCenterV2UserQueries: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.UserQueries}`,
  CpaCenterV2UserQueriesMessageView: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.UserQueries}/${CpaCenterV2RouteParts.QueryId}`,
  CpaCenterV2CCH: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.CCH}`,
  CpaCenterV2ReviewEsign: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.ReviewEsign}`,
  CpaCenterV2ReviewEsignReturnStep: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.ReviewEsign}/${CpaCenterV2RouteParts.ReturnId}/${CpaCenterV2RouteParts.TaxReturnSection}`,
  CpaCenterV2ReviewEsignQuery: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.ReviewEsign}/${CpaCenterV2RouteParts.ReturnId}/${CpaCenterV2RouteParts.TaxReturnSection}/${CpaCenterV2RouteParts.QueryId}`,
  CpaCenterV2DistributeDeductions: `${RouteName.CpaCenterV2}${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/${CpaCenterV2RouteParts.TaxProfileReview}/${CpaCenterV2RouteParts.DistributeDeductions}`,
};

export const BoiFilingCenterRoutesParts = {
  UserId: ':userId',
  DocumentReview: 'document-review',
  DocumentId: ':docId',
  ProfileReview: 'boi-profile-review',
};

export const BoiFilingCenterRoutes = {
  UserId: `${BoiFilingCenterRoutesParts.UserId}`,
  DocumentReview: `${RouteName.BoiFilingCenter}/${BoiFilingCenterRoutesParts.UserId}/${BoiFilingCenterRoutesParts.DocumentReview}`,
  DocumentViewer: `${RouteName.BoiFilingCenter}/${BoiFilingCenterRoutesParts.UserId}/${BoiFilingCenterRoutesParts.DocumentReview}/${BoiFilingCenterRoutesParts.DocumentId}`,
  BoiProfileReview: `${RouteName.BoiFilingCenter}/${BoiFilingCenterRoutesParts.UserId}/${BoiFilingCenterRoutesParts.ProfileReview}`,
};

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {TaxReturn} from 'src/store/taxReturns/taxReturns.reducer';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';

const useCurrentTaxReturnId = () => {
  const {returnId: rawReturnId} = useParams();

  let returnId: number = null;
  if (rawReturnId) {
    returnId = parseInt(rawReturnId, 10);
  }

  const {taxReturns, loaded} = useSelector(selectTaxReturnsReducer);

  const currentReturn = taxReturns.find(
    (taxReturn) => taxReturn.return_id === returnId,
  ) as TaxReturn;

  return {
    returnId,
    currentReturn,
    taxReturnsLoaded: loaded,
  };
};

export default useCurrentTaxReturnId;

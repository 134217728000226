export const BATCH_NOTIFICATION_THRESHOLD = 4;
export const DeductionClassChoices = [
  {id: 'DE', name: 'Deductible'},
  {id: 'ND', name: 'Non Deductible'},
  {id: 'PS', name: 'Possible Deduction'},
  {id: 'UN', name: 'Unclassified'},
  {id: 'IG', name: 'Ignored'},
];
export const DefaultDeductionChoice = {id: 'PS', name: 'Possible Deduction'};

export const LastModifierChoices = [
  {id: 'AL', name: 'Algo'},
  {id: 'RE', name: 'Reviewer'},
];

export const TxnStateChoices = [
  {id: 'INIT', name: 'INIT'},
  {id: 'PENDING_REVIEW', name: 'PENDING_REVIEW'},
  {id: 'PRE_READY', name: 'PRE_READY'},
  {id: 'DONT_SHOW', name: 'DONT_SHOW'},
  {id: 'READY', name: 'READY'},
  {id: 'USER_ACCEPTED', name: 'USER_ACCEPTED'},
];

export const TxnBucketChoices = [
  {id: 'NORMAL', name: 'NORMAL'},
  {id: 'LATER', name: 'LATER'},
  {id: 'CPA', name: 'CPA'},
];

export const BlankChoice = {id: '', name: '(none)'};

export const YearChoices = [
  {id: '2026', name: '2026'},
  {id: '2025', name: '2025'},
  {id: '2024', name: '2024'},
  {id: '2023', name: '2023'},
  {id: '2022', name: '2022'},
  {id: '2021', name: '2021'},
  {id: '2020', name: '2020'},
  {id: '2019', name: '2019'},
  {id: '2018', name: '2018'},
];

export const NotificationStateChoices = [
  {id: 'NONE', name: '✕'},
  {id: 'SENT', name: '✅'},
  {id: 'SCHEDULED', name: '🕑'},
  {id: 'ERRORED', name: '⚠'},
];

export const MerchantRuleLabel = [
  {id: 'MERCHANT_DB', name: 'Merchant - Merchant Database'},
  {id: 'MERCHANT_CLEAN', name: 'Merchant - Name Clean Up'},
];

export const CategoryRuleLabel = [
  {id: 'CAT_PLAID', name: 'Category - Plaid Category Rule'},
];
export const LandingScreenChoices = [
  {id: 'CLASSIFICATION_STATUS_BUCKETS', name: 'Classification Status Buckets'},
  {id: 'FILE_TAX_RETURN', name: 'File Tax Return'},
  {id: 'EXPORT_DATA', name: 'Export Data'},
  {id: 'MY_CARDS', name: 'My Cards'},
  {id: 'QTC', name: 'Quarterly Tax Calculator'},
];

export const AccountLinkStatusChoices = [
  {id: 'LINKED', name: 'Linked'},
  {id: 'NOT_LINKED', name: 'Not Linked'},
];

export const AppInstallStatusChoices = [
  {id: 'INSTALLED', name: 'Installed'},
  {id: 'UNINSTALLED', name: 'Uninstalled'},
];

export const TxnReviewedChoices = [
  {id: 'True', name: 'Reviewed'},
  {id: 'False', name: 'Not Reviewed'},
];

export const CurrentAmountChoices = [
  {id: 'POSITIVE', name: 'Positive'},
  {id: 'NEGATIVE', name: 'Negative'},
];
export const SeverityChoices = [
  {id: 'SEVERE', name: 'Severe'},
  {id: 'NOT_SEVERE', name: 'Not-Severe'},
];
export const NotSureCategoryReasonChoices = [
  {id: 'UNRECOGNIZED_MERCHANT', name: 'Unrecognized merchant'},
  {id: 'MORE_THAN_1_CATEGORY', name: 'More than 1 category seems correct'},
  {id: 'NO_CATEGORY_MATCH', name: 'None of the categories match exactly'},
  {id: 'OTHER', name: 'Other'},
];

export const IsPlaidCategoryCorrectChoices = [
  {id: 'True', name: 'Yes'},
  {id: 'False', name: 'No'},
];

export const TaxFilingStatusChoices = [
  {id: 'TAX_PROFILE_PENDING', name: 'Tax Profile Pending'},
  {id: 'TAX_PROFILE_SUBMIT', name: 'Tax Profile Submit'},
  {id: 'DOCUMENTS_RECEIVED', name: 'Documents Received'},
  {id: 'FILING_REVIEWED', name: 'Filing Reviewed'},
  {id: 'SIGNED_DOC_RECEIVED', name: 'Signed Doc Received'},
  {id: 'FILING_COMPLETED', name: 'Filing Completed'},
];

export const TaxOweOrRefundChoices = [
  {id: 'Owe', name: 'Owe'},
  {id: 'Refund', name: 'Refund'},
];

export const noStateTaxValidStates = [
  'AK',
  'FL',
  'SD',
  'NV',
  'TX',
  'WA',
  'WY',
];

export const USAStatesAbbreviations = [
  {id: 'AL', name: 'Alabama(AL)'},
  {id: 'AK', name: 'Alaska(AK)'},
  {id: 'AZ', name: 'Arizona(AZ)'},
  {id: 'AR', name: 'Arkansas(AR)'},
  {id: 'CA', name: 'California(CA)'},
  {id: 'CO', name: 'Colorado(CO)'},
  {id: 'CT', name: 'Connecticut(CT)'},
  {id: 'DE', name: 'Delaware(DE)'},
  {id: 'FL', name: 'Florida(FL)'},
  {id: 'GA', name: 'Georgia(GA)'},
  {id: 'HI', name: 'Hawaii(HI)'},
  {id: 'ID', name: 'Idaho(ID)'},
  {id: 'IL', name: 'Illinois(IL)'},
  {id: 'IN', name: 'Indiana(IN)'},
  {id: 'IA', name: 'Iowa(IA)'},
  {id: 'KS', name: 'Kansas(KS)'},
  {id: 'KY', name: 'Kentucky(KY)'},
  {id: 'LA', name: 'Louisiana(LA)'},
  {id: 'ME', name: 'Maine(ME)'},
  {id: 'MD', name: 'Maryland(MD)'},
  {id: 'MA', name: 'Massachusetts(MA)'},
  {id: 'MI', name: 'Michigan(MI)'},
  {id: 'MN', name: 'Minnesota(MN)'},
  {id: 'MS', name: 'Mississippi(MS)'},
  {id: 'MO', name: 'Missouri(MO)'},
  {id: 'MT', name: 'Montana(MT)'},
  {id: 'NE', name: 'Nebraska(NE)'},
  {id: 'NV', name: 'Nevada(NV)'},
  {id: 'NH', name: 'New Hampshire(NH)'},
  {id: 'NJ', name: 'New Jersey(NJ)'},
  {id: 'NM', name: 'New Mexico(NM)'},
  {id: 'NY', name: 'New York(NY)'},
  {id: 'NC', name: 'North Carolina(NC)'},
  {id: 'ND', name: 'North Dakota(ND)'},
  {id: 'OH', name: 'Ohio(OH)'},
  {id: 'OK', name: 'Oklahoma(OK)'},
  {id: 'OR', name: 'Oregon(OR)'},
  {id: 'PA', name: 'Pennsylvania(PA)'},
  {id: 'RI', name: 'Rhode Island(RI)'},
  {id: 'SC', name: 'South Carolina(SC)'},
  {id: 'SD', name: 'South Dakota(SD)'},
  {id: 'TN', name: 'Tennessee(TN)'},
  {id: 'TX', name: 'Texas(TX)'},
  {id: 'UT', name: 'Utah(UT)'},
  {id: 'VT', name: 'Vermont(VT)'},
  {id: 'VA', name: 'Virginia(VA)'},
  {id: 'WA', name: 'Washington(WA)'},
  {id: 'WV', name: 'West Virginia(WV)'},
  {id: 'WI', name: 'Wisconsin(WI)'},
  {id: 'WY', name: 'Wisconsin(WY)'},
  {id: 'DC', name: 'District of Columbia(DC)'},
];

export const USAStates = [
  {id: 'Alabama', name: 'Alabama(AL)'},
  {id: 'Alaska', name: 'Alaska(AK)'},
  {id: 'Arizona', name: 'Arizona(AZ)'},
  {id: 'Arkansas', name: 'Arkansas(AR)'},
  {id: 'California', name: 'California(CA)'},
  {id: 'Colorado', name: 'Colorado(CO)'},
  {id: 'Connecticut', name: 'Connecticut(CT)'},
  {id: 'Delaware', name: 'Delaware(DE)'},
  {id: 'Florida', name: 'Florida(FL)'},
  {id: 'Georgia', name: 'Georgia(GA)'},
  {id: 'Hawaii', name: 'Hawaii(HI)'},
  {id: 'Idaho', name: 'Idaho(ID)'},
  {id: 'Illinois', name: 'Illinois(IL)'},
  {id: 'Indiana', name: 'Indiana(IN)'},
  {id: 'Iowa', name: 'Iowa(IA)'},
  {id: 'Kansas', name: 'Kansas(KS)'},
  {id: 'Kentucky', name: 'Kentucky(KY)'},
  {id: 'Louisiana', name: 'Louisiana(LA)'},
  {id: 'Maine', name: 'Maine(ME)'},
  {id: 'Maryland', name: 'Maryland(MD)'},
  {id: 'Massachusetts', name: 'Massachusetts(MA)'},
  {id: 'Michigan', name: 'Michigan(MI)'},
  {id: 'Minnesota', name: 'Minnesota(MN)'},
  {id: 'Mississippi', name: 'Mississippi(MS)'},
  {id: 'Missouri', name: 'Missouri(MO)'},
  {id: 'Montana', name: 'Montana(MT)'},
  {id: 'Nebraska', name: 'Nebraska(NE)'},
  {id: 'Nevada', name: 'Nevada(NV)'},
  {id: 'New Hampshire', name: 'New Hampshire(NH)'},
  {id: 'New Jersey', name: 'New Jersey(NJ)'},
  {id: 'New Mexico', name: 'New Mexico(NM)'},
  {id: 'New York', name: 'New York(NY)'},
  {id: 'North Carolina', name: 'North Carolina(NC)'},
  {id: 'North Dakota', name: 'North Dakota(ND)'},
  {id: 'Ohio', name: 'Ohio(OH)'},
  {id: 'Oklahoma', name: 'Oklahoma(OK)'},
  {id: 'Oregon', name: 'Oregon(OR)'},
  {id: 'Pennsylvania', name: 'Pennsylvania(PA)'},
  {id: 'Rhode Island', name: 'Rhode Island(RI)'},
  {id: 'South Carolina', name: 'South Carolina(SC)'},
  {id: 'South Dakota', name: 'South Dakota(SD)'},
  {id: 'Tennessee', name: 'Tennessee(TN)'},
  {id: 'Texas', name: 'Texas(TX)'},
  {id: 'Utah', name: 'Utah(UT)'},
  {id: 'Vermont', name: 'Vermont(VT)'},
  {id: 'Virginia', name: 'Virginia(VA)'},
  {id: 'Washington', name: 'Washington(WA)'},
  {id: 'West Virginia', name: 'West Virginia(WV)'},
  {id: 'Wisconsin', name: 'Wisconsin(WI)'},
  {id: 'Wisconsin', name: 'Wisconsin(WY)'},
  {id: 'District of Columbia', name: 'District of Columbia(DC)'},
];

export const TaxProfileQuestionTypes = {
  LEGAL_NAME: 'LEGAL_NAME',
  SSN: 'SSN',
  HOME_ADDRESS: 'HOME_ADDRESS',
  CLAIM_YOU_DEPENDENT: 'CLAIM_YOU_DEPENDENT',
  FILING_STATUS: 'FILING_STATUS',
  SPOUSE_LEGAL_NAME: 'SPOUSE_LEGAL_NAME',
  SPOUSE_SSN: 'SPOUSE_SSN',
  SPOUSE_CLAIMED_DEPENDENT: 'SPOUSE_CLAIMED_DEPENDENT',
  DEPENDENT_DETAILS: 'DEPENDENT_DETAILS',
  EARNING_STATES: 'EARNING_STATES',
  INCOME_SOURCES: 'INCOME_SOURCES',
  YOUR_N_SPOUSE_INCOME_SOURCES: 'YOUR_N_SPOUSE_INCOME_SOURCES',
  FREELANCE_EARNINGS: 'FREELANCE_EARNINGS',
  RETIREMENT_PLAN_CONTRIBUTIONS: 'RETIREMENT_PLAN_CONTRIBUTIONS',
  DEDUCTION_OPTION: 'DEDUCTION_OPTION',
  ITEMIZE_DEDUCTION: 'ITEMIZE_DEDUCTION',
  STANDARD_DEDUCTION: 'STANDARD_DEDUCTION',
  ESTIMATED_TAX_PAYMENTS: 'ESTIMATED_TAX_PAYMENTS',
  RECEIVED_ADV_CHILD_TAX_CREDITS: 'RECEIVED_ADV_CHILD_TAX_CREDITS',
  BUSINESS_DETAILS: 'BUSINESS_DETAILS',
};

export const TaxProfileQuestions = {
  LEGAL_NAME: 'What is your legal name?',
  SSN: 'What is your SSN?',
  HOME_ADDRESS: 'What is your home address?',
  CLAIM_YOU_DEPENDENT: 'Can someone claim you as their dependent?',
  FILING_STATUS: 'What is your filing status for tax year 2021?',
  SPOUSE_LEGAL_NAME: 'Your spouse’s legal name?',
  SPOUSE_SSN: 'Your spouse’s Social Security Number?',
  SPOUSE_CLAIMED_DEPENDENT:
    'Can your spouse be claimed as someone’s dependent?',
  DEPENDENT_DETAILS: 'Your dependent details?',
  EARNING_STATES: 'Select all the state you earned income from',
  INCOME_SOURCES: 'Select all the income sources that apply to you',
  YOUR_N_SPOUSE_INCOME_SOURCES:
    'Income sources that apply to you & your spouse',
  FREELANCE_EARNINGS: 'How much did you earn from freelancing in year 2021?',
  RETIREMENT_PLAN_CONTRIBUTIONS:
    'Select all the retirement plan contibutions that apply to you',
  DEDUCTION_OPTION: 'Do you want to itemize your deductions?',
  ITEMIZE_DEDUCTION: 'Select the itemize deductions that apply to you',
  STANDARD_DEDUCTION: 'Select the standard deductions that apply to you',
  ESTIMATED_TAX_PAYMENTS:
    'Have you made any estimated tax payments in tax year 2021?',
  RECEIVED_ADV_CHILD_TAX_CREDITS:
    'Did you receive advance child tax credits in year 2021?',
  BUSINESS_DETAILS: 'What is the name of your business?',
};

export const IncomeScourcesChoices = [
  {id: 'W2 income', name: 'W2 income'},
  {id: 'Capital gains', name: 'Capital gains'},
  {id: 'Income from interest', name: 'Income from interest'},
  {id: 'Income from dividends', name: 'Income from dividends'},
  {id: 'Government benefits', name: 'Government benefits'},
  {id: 'Income from other sources', name: 'Income from other sources'},
];

export const RetirementPlanContriChoices = [
  {id: 'IRA', name: 'IRA'},
  {id: 'Keogh', name: 'Keogh'},
  {id: 'Simple', name: 'Simple'},
  {id: 'SEP', name: 'SEP'},
  {id: '401(k)', name: '401(k)'},
];

export const ItemizeDeductionChoices = [
  {id: 'Mortgage interest', name: 'Mortgage interest'},
  {id: 'Mortgage insurance premium', name: 'Mortgage insurance premium'},
  {id: 'Student loan interest', name: 'Student loan interest'},
  {id: 'Paid income and sales tax', name: 'Paid income and sales tax'},
  {id: 'Paid real estate tax', name: 'Paid real estate tax'},
  {id: 'Charity contributions', name: 'Charity contributions'},
  {id: 'Unreimbursed medical expenses', name: 'Unreimbursed medical expenses'},
  {id: 'Gambling losses', name: 'Gambling losses'},
];

export const StandardDeductionChoices = [
  {
    id: 'Self-employed health insurance',
    name: 'Self-employed health insurance',
  },
  {id: 'Student loan interest', name: 'Student loan interest'},
  {id: 'Charity contributions', name: 'Charity contributions'},
  {id: 'HSA contributions', name: 'HSA contributions'},
];

export const EstimatedTaxPaymentChoices = [
  {id: 'Federal taxes', name: 'Federal taxes'},
  {id: 'State taxes', name: 'State taxes'},
  {id: 'None of the above', name: 'None of the above'},
];

export const CPAQuestionStatusChoices = [
  {id: 'OPEN', name: 'Open'},
  {id: 'SENT', name: 'Sent'},
  {id: 'RESOLVED', name: 'Resolved'},
];

export const CPAQuestionTypeChoices = [
  {id: 'GENERAL', name: 'General'},
  {id: 'TXN_RELATED', name: 'Txn Related'},
];

export const LocalStorageKey = {
  Token: 'token',
  UserId: 'id',
  UserName: 'user_name',
  UserEmail: 'user_email',
  UserFirstName: 'user_first_name',
  UserLastName: 'user_last_name',
  AdminToken: 'admin_token',
};

export const ROUTES_PLACEHOLDERS = {
  FLYFIN_USER_ID_PLACEHOLDER: 'FLYFIN_USER_ID_PLACEHOLDER',
  DOCUMENT_TYPE_ID_PLACEHOLDER: 'DOCUMENT_TYPE_ID_PLACEHOLDER',
  DOCUMENT_ID_PLACEHOLDER: 'DOCUMENT_ID_PLACEHOLDER',
  QUERY_ID_PLACEHOLDER: 'QUERY_ID_PLACEHOLDER',
};

export enum CLASSIFICATION_STATUS {
  POSSIBLE = 'PS',
  DEDUCTION = 'DE',
  NON_DEDUCTION = 'ND',
  ALL_CLASSIFIED = 'DE,PS,ND',
  POSSIBLE_INCOME = 'PI',
  NOT_INCOME = 'NI',
  INCOME = 'IN',
}

export enum BUCKET {
  LATER = 'LATER',
  CPA = 'CPA',
  NORMAL = 'NORMAL',
}

export const DOCUMENT_STATUS = {
  ACCEPT: 'Accept',
  ACTION_REQUIRED: 'Action required',
};

export enum VALID_DOCUMENT_STATUS {
  PENDING_FOR_REVIEW = 'PENDING_FOR_REVIEW',
  ACCEPTED = 'ACCEPTED',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  IGNORED = 'IGNORED',
  REVIEWED_OCR_DATA = 'REVIEWED_OCR_DATA',
}

export enum VALID_DOCUMENT_REASON {
  WRONG_DOC = 'WRONG_DOC',
  DOC_NOT_CLEAR = 'DOC_NOT_CLEAR',
  INFO_MISMATCH = 'INFO_MISMATCH',
  ADDITIONAL_DOC = 'ADDITIONAL_DOC',
  INCOMPLETE_DOC = 'INCOMPLETE_DOC',
  GENERAL = 'GENERAL',
  TAX_PROFILE_CPA = 'TAX_PROFILE_CPA',
  TAX_RETURN_WRONG_INFO = 'TAX_RETURN_WRONG_INFO',
  TAX_RETURN_MISSING_INFO = 'TAX_RETURN_MISSING_INFO',
}

export enum QUERY_STATUS {
  RESOLVED = 'RESOLVED',
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
}

export enum AuthorType {
  END_USER = 'END_USER',
  CPA = 'CPA',
  SYSTEM = 'SYSTEM',
}

export enum CPAQuestionMessageType {
  CHAT = 'CHAT',
  INFO = 'INFO',
  DOC_ATTACHED = 'DOC_ATTACHED',
  TAX_RETURN_MISSING_INFO = 'TAX_RETURN_MISSING_INFO',
  TAX_RETURN_WRONG_INFO = 'TAX_RETURN_WRONG_INFO',
  DOCUMENT_PROOF = 'DOCUMENT_PROOF',
  CALL_INVITE = 'CALL_INVITE',
  CALL_CANCELLATION = 'CALL_CANCELLATION',
  GENERAL = 'GENERAL',
  DEEPLINK = 'DEEPLINK',
}

export enum CPA_CALL_STATUS {
  NA = 'NA',
  CALL_MESSAGE_SENT = 'CALL_MESSAGE_SENT',
  CALL_SCHEDULED = 'CALL_SCHEDULED',
  CALL_CANCELLED = 'CALL_CANCELLED',
  CALL_OVER = 'CALL_OVER',
}

export enum CPAQuestionType {
  GENERAL = 'GENERAL',
  TXN_RELATED = 'TXN_RELATED',
  TAX_FILING = 'TAX_FILING',
  QTC = 'QTC',

  /**
   * @deprecated this is migrated to type: TAX_FILING & sub_type: TAX_PROFILE
   */
  TAX_PROFILE = 'TAX_PROFILE',

  /**
   * @info type used only for filtering queries in return center queries
   */
  TAX_FILING_N_TAX_PROFILE = 'TAX_FILING_N_TAX_PROFILE',
}

export const CPAQuestionTypeName = {
  [CPAQuestionType.QTC]: 'Qtc',
  [CPAQuestionType.TAX_FILING]: 'Tax Filing',
  [CPAQuestionType.TXN_RELATED]: 'Expense',
  [CPAQuestionType.GENERAL]: 'General',
  [CPAQuestionType.TAX_PROFILE]: 'Tax Profile',
  [CPAQuestionType.TAX_FILING_N_TAX_PROFILE]: 'Tax Filing',
};

export enum DOCUMENT_TYPES {
  DL_OR_STATE_ISSUED_ID = 'DL_OR_STATE_ISSUED_ID',
  LAST_YEAR_TAX_RETURN_FORM_1040 = 'LAST_YEAR_TAX_RETURN_FORM_1040',
  W2_FORM = 'W2_FORM',
  FORM_1099_INT = 'FORM_1099_INT',
  FORM_1099_DIV = 'FORM_1099_DIV',
  FORM_1099_G = 'FORM_1099_G',
  FORM_1099_B = 'FORM_1099_B',
  FORM_1098_T = 'FORM_1098_T',
  FORM_1095_A = 'FORM_1095_A',
  FORM_1120 = 'FORM_1120',
  FORM_1120_S = 'FORM_1120_S',
  FORM_1065 = 'FORM_1065',
  CHARITABLE_CONTRI_DOC = 'CHARITABLE_CONTRI_DOC',
  BUSINESS_REGISTRATION_DOC = 'BUSINESS_REGISTRATION_DOC',
  FORM_401_K = 'FORM_401_K',
  IRA_CONTRI_PROOF = 'IRA_CONTRI_PROOF',
  ESTIMATED_TAX_PAYMENT_PROOF = 'ESTIMATED_TAX_PAYMENT_PROOF',
  OTHER = 'OTHER',
  MORTGAGE = 'MORTGAGE',
  STATE_N_LOCAL_TAX = 'STATE_N_LOCAL_TAX',
  STATE_N_LOCAL_REAL_ESTATE_TAX = 'STATE_N_LOCAL_REAL_ESTATE_TAX',
  PERSONAL_PROPERTY_TAX = 'PERSONAL_PROPERTY_TAX',
  GAMBLING_LOSS = 'GAMBLING_LOSS',
  CASUALTY_N_THEFT_LOSS = 'CASUALTY_N_THEFT_LOSS',
  D1099_R = 'D1099_R',
  D1099_SA = 'D1099_SA',
  K_1_1120S_1065 = 'K_1_1120S_1065',
  MEDICAL_EXPENSE_PROOF = 'MEDICAL_EXPENSE_PROOF',
  STUDENT_LOAN_INTEREST = 'STUDENT_LOAN_INTEREST',
  VEHICLE_LOAN = 'VEHICLE_LOAN',
  SPOUSE_DL = 'SPOUSE_DL',
  K_1 = 'K_1',
  STATE_TAX_RETURN = 'STATE_TAX_RETURN',
  OTHER_1 = 'OTHER_1',
  OTHER_2 = 'OTHER_2',
  OTHER_3 = 'OTHER_3',
  OTHER_4 = 'OTHER_4',
  OTHER_5 = 'OTHER_5',
  OTHER_6 = 'OTHER_6',
  OTHER_7 = 'OTHER_7',
  OTHER_8 = 'OTHER_8',
  OTHER_9 = 'OTHER_9',
  OTHER_10 = 'OTHER_10',
  FORM_1099_NEC_K_MISC = 'FORM_1099_NEC_K_MISC',
  TAX_RETURNS = 'TAX_RETURNS',
  ESIGN_FORM = 'ESIGN_FORM',
  OTHER_LOAN_DOCUMENT = 'OTHER_LOAN_DOCUMENT',
  SA_5498 = 'SA_5498',
  BUSINESS_LOAN = 'BUSINESS_LOAN',
  S_CORP_ELECTION = 'S_CORP_ELECTION',
  DIGITAL_ASSETS = 'DIGITAL_ASSETS',
  FORM_1099_MISC = 'FORM_1099_MISC',
  FORM_1099_SSA = 'FORM_1099_SSA',
  FORM_1099_C = 'FORM_1099_C',
  FORM_1099_OID = 'FORM_1099_OID',
  FOREIGN_INCOME_DOCUMENT = 'FOREIGN_INCOME_DOCUMENT',
  EMPLOYEES_W2_FORM = 'EMPLOYEES_W2_FORM',
  DEPRECIATION_REPORT = 'DEPRECIATION_REPORT',
  BOIR_INCORPORATION_DOC = 'BOIR_INCORPORATION_DOC',
  BOIR_BENEFICIAL_OWNER_ID = 'BOIR_BENEFICIAL_OWNER_ID',
  PASSPORT = 'PASSPORT',
  BOIR_INCORPORATION_CERTIFICATE = 'BOIR_INCORPORATION_CERTIFICATE',
  BOIR_PAYROLL_REPORT = 'BOIR_PAYROLL_REPORT',
  BOIR_TAX_RETURN = 'BOIR_TAX_RETURN',
  BOIR_OWNERSHIP_LEASE_DOC = 'BOIR_OWNERSHIP_LEASE_DOC',
}

export const additionalDocumentsList = [
  DOCUMENT_TYPES.LAST_YEAR_TAX_RETURN_FORM_1040,
  DOCUMENT_TYPES.FORM_1120,
  DOCUMENT_TYPES.FORM_1120_S,
  DOCUMENT_TYPES.FORM_1065,
  DOCUMENT_TYPES.K_1_1120S_1065,
  DOCUMENT_TYPES.MORTGAGE,
  DOCUMENT_TYPES.STUDENT_LOAN_INTEREST,
  DOCUMENT_TYPES.FORM_1098_T,
  DOCUMENT_TYPES.ESTIMATED_TAX_PAYMENT_PROOF,
  DOCUMENT_TYPES.FORM_1099_SSA,
  DOCUMENT_TYPES.FORM_1099_C,
  DOCUMENT_TYPES.FORM_1099_OID,
  DOCUMENT_TYPES.FOREIGN_INCOME_DOCUMENT,
  DOCUMENT_TYPES.EMPLOYEES_W2_FORM,
  DOCUMENT_TYPES.DEPRECIATION_REPORT,
  DOCUMENT_TYPES.STATE_TAX_RETURN,
];

export const ALL_DOCS_TYPE = Object.values(DOCUMENT_TYPES);

export enum ALL_FORM_TYPES {
  FORM_1099_B = 'FORM_1099_B',
  W2_G_FORM = 'W2_G_FORM',
  FORM_1099_SA = 'FORM_1099_SA',
  MANUAL_FREELANCE_INCOME_FORM = 'MANUAL_FREELANCE_INCOME_FORM',
  W2_FORM = 'W2_FORM',
  FORM_1099_NEC = 'FORM_1099_NEC',
  FORM_1099_MISC = 'FORM_1099_MISC',
  FORM_1099_K = 'FORM_1099_K',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  FORM_1099_INT = 'FORM_1099_INT',
  FORM_1098_E = 'FORM_1098_E',
  FORM_1098_T = 'FORM_1098_T',
  FORM_1099_DIV = 'FORM_1099_DIV',
  FORM_1099_R = 'FORM_1099_R',
  FORM_1099_G = 'FORM_1099_G',
  FORM_1095_A = 'FORM_1095_A',
  FORM_1120 = 'FORM_1120',
  FORM_1120_S = 'FORM_1120_S',
  FORM_1065 = 'FORM_1065',
  OTHER = 'OTHER',
  FORM_1040_NORMAL = 'FORM_1040_NORMAL',
  FORM_1040_SCHEDULE_1 = 'FORM_1040_SCHEDULE_1',
  FORM_1040_SCHEDULE_C = 'FORM_1040_SCHEDULE_C',
  FORM_1040_SCH_K1_1120 = 'FORM_1040_SCH_K1_1120',
  FORM_1040_SCH_K1_1065 = 'FORM_1040_SCH_K1_1065',
  FORM_1040_SCHEDULE_K1 = 'FORM_1040_SCHEDULE_K1',
  BUSINESS_REG_FORM = 'BUSINESS_REG_FORM',
  DEPENDENT = 'DEPENDENT',
  BUSINESS_LOAN = 'BUSINESS_LOAN',
  DEPRECIATION = 'DEPRECIATION',
  FORM_1098_MIS = 'FORM_1098_MIS',
  BUSINESS_VEHICLE = 'BUSINESS_VEHICLE',
  HOME_DEPRECIATION = 'HOME_DEPRECIATION',
  CHARITY_CONTRIBUTION_RECEIPT = 'CHARITY_CONTRIBUTION_RECEIPT',
  FORM_SA_5498 = 'FORM_SA_5498',
  STATES_LIVED = 'STATES_LIVED',
  QUARTERLY_TAX_PAYMENTS = 'QUARTERLY_TAX_PAYMENTS',
  RENTAL_INCOME = 'RENTAL_INCOME',
  FORM_K1_1120S = 'FORM_K1_1120S',
  FORM_K1_1065 = 'FORM_K1_1065',
  BOIR_BENEFICIAL_OWNER_DETAILS = 'BOIR_BENEFICIAL_OWNER_DETAILS',
}

export const INCOME_FORMS = [
  ALL_FORM_TYPES.FORM_1099_MISC,
  ALL_FORM_TYPES.FORM_1099_NEC,
  ALL_FORM_TYPES.FORM_1099_K,
  ALL_FORM_TYPES.W2_FORM,
  ALL_FORM_TYPES.FORM_1099_INT,
  ALL_FORM_TYPES.FORM_1099_DIV,
  ALL_FORM_TYPES.FORM_1099_B,
  ALL_FORM_TYPES.W2_G_FORM,
  ALL_FORM_TYPES.FORM_1099_G,
  ALL_FORM_TYPES.FORM_1099_R,
  ALL_FORM_TYPES.FORM_1099_SA,
  ALL_FORM_TYPES.MANUAL_FREELANCE_INCOME_FORM,
];

export const STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const DEFAULT_PER_PAGE = 10;
export const MAX_PER_PAGE_LIMIT = 2000;
export const RESOLVED_OPACITY = 0.5;

export const CPA_CENTER_ACTIVE_YEAR = 2023;

export const themmeColor = {
  grey: '#555555',
  errorRed: '#FF4B4B',
  offWhite: '#E1E1E1',
  white: '#FFFFFF',
  black: '#000000',
  focusedBlue: '#EEF8FF',
  black60: '#4C4C4C',
  darkBlue: '#1167CB',
  successGreen: '#0CAB4E',
  lightGreen: '#D4FFD9',
  flyfinOrange: '#FF6E4A',
  flyfinYellow: '#C28C00',
  silver: '#A5A5A5',
  backgroundOffWhite: '#F5F5F5',
  black80: '#212121',
  translucentYellow: '#EAEC9F42',
  translucentRed: '#FFF0EC',
  translucentGreen: '#EBFFD7',
  lightGrey: '#D9D9D9',
  skyBlue: '#8CC0FF',
  lightSkyBlue: '#D7E9FF',
  antiqueWhite: '#FFEAD7',
  hitPink: '#FFAF8C',
  linen: '#FFEAEA',
  deleteRed: '#A23921',
  statusFlyfinYellow: '#FFC94A',
  lightYellow: '#FFF4D7',
  lightBlue: '#E0EBFF',
  hoverColor: '#F4F9FF',
  lightFlyfinOrange: '#FFECEC',
  lightSuccessGreen: '#ECFFF8',
  darkYellow: '#685633',
  dashboardRed: '#AC7777',
  resolvedGreen: '#77AC82',
  sentGrey: '#9B9B9B',
  black70: '#333333',
  cpaCenterV2Bg: '#F5F6F8',
  offGrey: '#E7E8EB',
  cpaCenterV2QueryBox: '#F3F3F3',
  darkGreen: '#397B21',
  selectedQueryBackground: '#FBF9FF',
  textPurple: '#745699',
  deductionGreen: '#DDFFE0',
  draftQueryBgColor: '#F9F1F1',
};

export enum ReturnSummaryKeys {
  agi = 'agi',
  income_tax = 'income_tax',
  income_tax_withheld = 'income_tax_withheld',
  itemized_deduction = 'itemized_deduction',
  self_employment_tax = 'self_employment_tax',
  taxable_income = 'taxable_income',
  tax_due_amount = 'tax_due_amount',
  tax_refund_amount = 'tax_refund_amount',
  standard_deduction = 'standard_deduction',
}

export const RETURN_SUMMARY_CATEGORY = {
  [ReturnSummaryKeys.agi]: 'Adjusted gross income',
  [ReturnSummaryKeys.income_tax]: 'Income tax',
  [ReturnSummaryKeys.income_tax_withheld]: 'Income tax withheld',
  [ReturnSummaryKeys.itemized_deduction]: 'Itemize deduction',
  [ReturnSummaryKeys.self_employment_tax]: 'Self employment tax',
  [ReturnSummaryKeys.taxable_income]: 'Taxable income',
  [ReturnSummaryKeys.tax_due_amount]: 'Tax due amount',
  [ReturnSummaryKeys.tax_refund_amount]: 'Tax refund amount',
  [ReturnSummaryKeys.standard_deduction]: 'Standard deduction',
};

export enum VALID_CPA_EMAILS {
  Unassigned = 'unassigned',
  Varsha = 'varsha@flyfin.org',
  Shubham = 'shubhammadaan@flyfin.org',
  Aishwarya = 'aishwarya@flyfin.org',
  Sreeraj = 'sreeraj@flyfin.org',
  Sachin = 'sachin@flyfin.org',
  Kuldeep = 'kuldeep@flyfin.org',
  Anshul = 'anshul@flyfin.org',
  Vidhi = 'vidhisoni@flyfin.org',
  Prem = 'premsoni@flyfin.org',
  Bhavya = 'bhavyajain@flyfin.org',
  Mayur = 'mayurinagrecha@flyfin.org',
  Himanshu = 'himanshumittal@flyfin.org',
  Gowtham = 'gowthamreddy@flyfin.org',
  RamaPrasad = 'ramaprasadchary@flyfin.org',
  DhanyaMohan = 'dhanyamohan@flyfin.org',
  Tawqeer = 'tawqeer@flyfin.org',
}

export const TAX_DUE_PAYMENT_METHOD = {
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  OTHER: 'OTHER',
} as const;

export const PROD_ALLOW_USER_DELETION_EMAILS = [
  'aman@flyfin.org',
  'amber@flyfin.org',
  'sachin@flyfin.org',
  'varun@flyfin.org',
  'lakshyabang@flyfin.org',
  'tirth@flyfin.org',
  'ishaan@flyfin.org',
  'spandey@flyfin.org',
  'raj.shah@flyfin.org',
];

export enum TaxProfileSection {
  BasicDetails = 'Basic-details',
  Income = 'Income',
  Deductions = 'Deductions',
  Credits = 'Credits',
  TaxPayments = 'Tax-payments',
  StateTaxes = 'State-taxes',
}

export const TaxProfileSectionSequence = [
  {
    stepName: 'Basic details',
    section: TaxProfileSection.BasicDetails,
  },
  {
    stepName: 'Income',
    section: TaxProfileSection.Income,
  },
  {
    stepName: 'Deduction',
    section: TaxProfileSection.Deductions,
  },
  {
    stepName: 'Credits',
    section: TaxProfileSection.Credits,
  },
  {
    stepName: 'Tax payments',
    section: TaxProfileSection.TaxPayments,
  },
  {
    stepName: 'State taxes',
    section: TaxProfileSection.StateTaxes,
  },
];

export enum TaxProfileSubsection {
  // Basic Details
  UserDetails = 'USER_DETAILS',
  HomeAddress = 'HOME_ADDRESS',
  SpouseDetails = 'SPOUSE_DETAILS',
  DependentDetails = 'DEPENDENT_DETAILS',
  BusinessDetails = 'BUSINESS_DETAILS',

  // Deductions
  HomeOfficeDetails = 'HOME_OFFICE_DEDUCTIONS',
  BusinessVehicleDetails = 'BUSINESS_VEHICLE_DEDUCTIONS',
  PhoneAndInternet = 'PHONE_AND_INTERNET_DEDUCTIONS',
  OfficeMortgage = 'OFFICE_MORTGAGE_DEDUCTIONS',
  StudentLoan = 'STUDENT_LOAN_DEDUCTIONS',
  BusinessLoans = 'BUSINESS_LOAN_DEDUCTIONS',
  SalaryAndWagesPaid = 'SALARY_AND_WAGES_DEDUCTIONS',
  OtherDepreciations = 'DEPRECIATION_DEDUCTIONS',
  HSAContributions = 'HSA_DEDUCTIONS',
  IRAContributions = 'IRA_DEDUCTIONS',
  HealthInsurancePremium = 'HEALTH_INSURANCE_PREMIUM_DEDUCTIONS',
  PersonalDeductions = 'PERSONAL_DEDUCTIONS',
  AllOtherDeductions = 'ALL_OTHER_DEDUCTIONS',

  // Income
  Freelance = 'FREELANCE_INCOME',
  W2Income = 'W2_INCOME',
  Interest = 'INTEREST_INCOME',
  Dividend = 'DIVIDEND_INCOME',
  Investment = 'INVESTMENT_INCOME',
  Gambling = 'GAMBLING_INCOME',
  Government = 'GOVERNMENT_INCOME',
  Retirement = 'RETIREMENT_INCOME',
  HSA = 'HSA_INCOME',
  RentalIncome = 'RENTAL_INCOME',
  DigitalAssetIncome = 'DIGITAL_ASSETS_INCOME',
  K1_INCOME = 'K1_INCOME',

  // Credits
  DependentCredits = 'DEPENDENT_CREDITS',
  EducationalCredits = 'EDUCATIONAL_CREDITS',
  AdoptionCredits = 'ADOPTION_CREDITS',

  // Tax Payments
  Payments = 'PAYMENTS',

  // States
  StatesTaxFilingInformation = 'STATES_TAX_FILING_INFORMATION',
}

export const TaxProfileSectionToSubsectionMapping = {
  [TaxProfileSection.BasicDetails]: [
    TaxProfileSubsection.UserDetails,
    TaxProfileSubsection.HomeAddress,
    TaxProfileSubsection.SpouseDetails,
    TaxProfileSubsection.DependentDetails,
    TaxProfileSubsection.BusinessDetails,
  ],
  [TaxProfileSection.Deductions]: [
    TaxProfileSubsection.HomeOfficeDetails,
    TaxProfileSubsection.BusinessVehicleDetails,
    TaxProfileSubsection.PhoneAndInternet,
    TaxProfileSubsection.OfficeMortgage,
    TaxProfileSubsection.StudentLoan,
    TaxProfileSubsection.BusinessLoans,
    TaxProfileSubsection.SalaryAndWagesPaid,
    // TaxProfileSubsection.OtherDepreciations,
    TaxProfileSubsection.HSAContributions,
    TaxProfileSubsection.IRAContributions,
    TaxProfileSubsection.HealthInsurancePremium,
    TaxProfileSubsection.PersonalDeductions,
    TaxProfileSubsection.AllOtherDeductions,
  ],
  [TaxProfileSection.Income]: [
    TaxProfileSubsection.Freelance,
    TaxProfileSubsection.W2Income,
    TaxProfileSubsection.Interest,
    TaxProfileSubsection.Dividend,
    TaxProfileSubsection.Investment,
    TaxProfileSubsection.RentalIncome,
    TaxProfileSubsection.DigitalAssetIncome,
    TaxProfileSubsection.Investment,
    TaxProfileSubsection.Gambling,
    TaxProfileSubsection.Government,
    TaxProfileSubsection.Retirement,
    TaxProfileSubsection.HSA,
    TaxProfileSubsection.K1_INCOME,
  ],
  [TaxProfileSection.Credits]: [
    TaxProfileSubsection.DependentCredits,
    TaxProfileSubsection.EducationalCredits,
    TaxProfileSubsection.AdoptionCredits,
  ],
  [TaxProfileSection.TaxPayments]: [TaxProfileSubsection.Payments],
  [TaxProfileSection.StateTaxes]: [
    TaxProfileSubsection.StatesTaxFilingInformation,
  ],
};

export enum DependentRelations {
  Son = 'Son',
  Daughter = 'Daughter',
  AdoptedChild = 'Adopted child',
  FosterChild = 'Foster child',
  Stepchild = 'Stepchild',
  Grandchild = 'Grandchild',
  Brother = 'Brother',
  Sister = 'Sister',
  Nephew = 'Nephew',
  Niece = 'Niece',
  Uncle = 'Uncle',
  Aunt = 'Aunt',
  Grandfather = 'Grandfather',
  Grandmother = 'Grandmother',
  Mother = 'Mother',
  Father = 'Father',
  UnrelatedDependent = 'Unrelated Dependent',
  FosterParent = 'Foster Parent',
  Cousin = 'Cousin',
  Friend = 'Friend',
}

export enum COMMON_COMPONENTS_ID {
  CPA_CENTER_V2_USER_SUMMARY_BAR = 'CPA_CENTER_V2_USER_SUMMARY_BAR',
  CPA_CENTER_V2_TAX_FILING_STEPS = 'CPA_CENTER_V2_TAX_FILING_STEPS',
  CPA_CENTER_V2_MESSAGE_LIST_STICKY_FOOTER = 'CPA_CENTER_V2_MESSAGE_LIST_STICKY_FOOTER',
  CPA_CENTER_V2_TAX_RETURN_SECTION_HEADER = 'CPA_CENTER_V2_TAX_RETURN_SECTION_HEADER',
}

export enum TAX_FILING_STATUS {
  NOT_INITIATED = 'NOT_INITIATED',
  TAX_PROFILE_SUBMITTED = 'TAX_PROFILE_SUBMITTED',
  TAX_DOCS_REVIEWED = 'TAX_DOCS_REVIEWED',
  TAX_PROFILE_REVIEWED = 'TAX_PROFILE_REVIEWED',
  USER_QUERIES_SENT = 'USER_QUERIES_SENT',
  PREP_STARTED = 'PREP_STARTED',
  DETAILS_SENT_TO_CCH = 'DETAILS_SENT_TO_CCH',
  RETURN_ADDED = 'RETURN_ADDED',
  SUMMARY_REVIEWED = 'SUMMARY_REVIEWED',
  RETURN_SENT_TO_USER = 'RETURN_SENT_TO_USER',
  USER_ESIGNED = 'USER_ESIGNED',
  BANK_DETAILS_SUBMITTED = 'BANK_DETAILS_SUBMITTED',
  EFILED = 'EFILED',
  REJECTED_FROM_IRS = 'REJECTED_FROM_IRS',
  APPROVED_FROM_IRS = 'APPROVED_FROM_IRS',
}

export enum CPA_ACK_TEMPLATE_NAME {
  SUBMIT_TO_CPA_WEEKDAYS = 'SUBMIT_TO_CPA_WEEKDAYS',
  SUBMIT_TO_CPA_WEEKENDS = 'SUBMIT_TO_CPA_WEEKENDS',
}

export const CPA_TEMPLATE_SAMPLE_MESSAGE = {
  [CPA_ACK_TEMPLATE_NAME.SUBMIT_TO_CPA_WEEKDAYS]:
    'Hi, thank you for submitting your documents. Please allow us one business day to get back to you either for clarifications or to confirm that everything looks good. Once all the information is looking good, we will prepare your return - generally takes 3 days.',
  [CPA_ACK_TEMPLATE_NAME.SUBMIT_TO_CPA_WEEKENDS]:
    'Hi, thank you for submitting your tax documents. Please note that we are away on the weekends and would be reviewing your documents as soon as we are back.',
};

export const BROWER_LOCAL_STORAGE_KEYS = {
  DRAWER_WIDTH: 'drawer-width', // stores the side drawer width set by user
};

export enum TAX_EXTENSION_STATUS {
  REQUEST = 'REQUEST',
  FILED = 'FILED',
  /**
   * @deprecated old tax filing extension status
   */
  AWAITING_DETAILS = 'AWAITING_DETAILS',
}

export enum FILED_TAX_EXTENSION_ANSWER {
  YES = 'Yes',
  NO = 'No',
}

export const EXTENSION_DEADLINE = new Date('2024-04-15');

export const DATE_FORMATS = {
  DB_FORMAT: 'YYYY-MM-DD',
  DISPLAY_FORMAT: 'MMM d, yyyy',
  DISPLAY_FORMAT_2: 'do MMM',
  DEFAULT_FORMAT: "yyyy-MM-dd'T'HH:mm:ssxxx",
  TIME_FORMAT: 'HH:mm',
  DAY_OF_MONTH: 'do',
  RANGE_FORMAT: "MMM d ''yy",
};

import {Typography} from '@mui/material';
import {format} from 'date-fns';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {
  MessageAuthorType,
  ReduxMessageType,
} from 'src/store/queries/queries.reducer';

interface MessageHeaderProps {
  message: ReduxMessageType;
}

const MessageHeader = ({message}: MessageHeaderProps) => {
  const timeString = format(message.messageCreatedAt, 'dd/MM/yyyy, HH:mm');

  return (
    <div>
      <Typography style={{fontSize: 11, color: themmeColor.grey}}>
        {timeString}
      </Typography>
    </div>
  );
};

export default MessageHeader;

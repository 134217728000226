import {Typography} from '@mui/material';
import {format} from 'date-fns';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import {
  MessageAuthorType,
  MessageSubType,
} from 'src/store/queries/queries.reducer';
import Box from 'src/DesignSystem/Box/Box';
import TickIcon from 'src/icons/TickIcon';
import WarningIcon from 'src/icons/WarningIcon';
import {MessageProps} from './Message';
import MessageHeader from './MessageHeader';

export const HistoryMessage = ({message}: MessageProps) => {
  const isFromUser = message.authorType === MessageAuthorType.END_USER;

  const isResolvedType = message.messageSubType === MessageSubType.RESOLVED;
  return (
    <div
      style={{
        flex: 1,
        alignItems: isFromUser ? 'flex-start' : 'flex-end',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <MessageHeader message={message} />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingBlock: 8,
          paddingInline: 16,
          backgroundColor: isResolvedType
            ? themmeColor.deductionGreen
            : themmeColor.cpaCenterV2Bg,
          maxWidth: '80%',
          alignItems: 'center',
        }}>
        {
          <div style={{marginRight: 8, display: 'flex'}}>
            {isResolvedType ? <TickIcon /> : <WarningIcon />}
          </div>
        }
        <Typography
          style={{
            fontWeight: 600,
            fontSize: 11,
            textTransform: 'uppercase',
          }}>
          Question {isResolvedType ? 'resolved' : 're-opened'} by{' '}
          {isFromUser ? message.authorName : `CPA (${message.authorName})`}
        </Typography>
      </Box>
    </div>
  );
};

import {Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  TaxProfileSection,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import Skeleton from 'src/icons/Skeleton';
import {setAppState} from 'src/store/app/app.actions';
import {
  AppReducerStates,
  CreateQueryDrawerTab,
} from 'src/store/app/app.reducer';
import {
  DerivedQueryType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import {selectQueriesReducer} from 'src/store/queries/queries.selector';
import {getTaxProfileSectionFromSubsection} from '../CpaCenterV2.utils';
import QueryPreview from '../QueryPreview/QueryPreview';
import _ from 'lodash';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';

interface ActionRequiredProps {
  types: CreateQueryDrawerTab[];
  dontAllowAdd?: boolean;
  onClickAddSideEffect?: () => void;
  taxProfileSection?: TaxProfileSection;
}

const ActionRequired = ({
  types,
  dontAllowAdd = false,
  onClickAddSideEffect = () => {},
  taxProfileSection,
}: ActionRequiredProps) => {
  const dispatch = useDispatch();
  const {queries, loaded} = useSelector(selectQueriesReducer);
  const {navigateToMessagesView} = useCpaCenterV2Navigation();

  const getFilteredQueries = () => {
    const queriesWithKnownType = queries.filter(
      (q) => q.derivedQueryType !== DerivedQueryType.UNKNOWN,
    );
    let groups = _.groupBy(queriesWithKnownType, 'derivedQueryType');
    Object.values(DerivedQueryType).forEach((key) => {
      groups[key] = groups[key] ?? [];
    });
    return groups;
  };

  const filteredQueries = getFilteredQueries();

  const getTaxProfileQueriesGroupedBySection = () => {
    const grouped = _.groupBy(
      filteredQueries[DerivedQueryType.TAX_PROFILE_CPA],
      (query) =>
        getTaxProfileSectionFromSubsection(query.taxProfileSubsection),
    );
    let sectionNames = Object.keys(grouped);
    if (taxProfileSection) {
      if (sectionNames.includes(taxProfileSection)) {
        sectionNames = [taxProfileSection];
      } else {
        sectionNames = [];
      }
    }
    return {grouped, sectionNames};
  };

  const getQueriesCount = () => {
    let count = 0;
    if (types.includes(CreateQueryDrawerTab.DOCUMENT)) {
      count +=
        filteredQueries[DerivedQueryType.REUPLOAD_DOCUMENT].length +
        filteredQueries[DerivedQueryType.UPLOAD_NEW_DOCUMENT].length;
    }
    if (types.includes(CreateQueryDrawerTab.TAX_PROFILE)) {
      const {sectionNames, grouped} = getTaxProfileQueriesGroupedBySection();
      sectionNames?.forEach(
        (sectionName) => (count += grouped[sectionName].length),
      );
    }
    return count;
  };

  const renderQuery = (query: ReduxQueryType) => {
    return (
      <div style={{marginBottom: 10}}>
        <QueryPreview
          onClick={navigateToMessagesView}
          key={query.queryId}
          query={query}
          isDraftEditDeleteAllowed={true}
        />
      </div>
    );
  };

  const renderQuerySection = () => {
    const queriesJSX = [];
    if (types.includes(CreateQueryDrawerTab.DOCUMENT)) {
      queriesJSX.push(
        <>
          <Typography
            style={{
              color: themmeColor.black60,
              fontWeight: 600,
              fontSize: 16,
              marginBlock: 12,
            }}>
            Document
          </Typography>
          {filteredQueries[DerivedQueryType.UPLOAD_NEW_DOCUMENT].map((query) =>
            renderQuery(query),
          )}
          {filteredQueries[DerivedQueryType.REUPLOAD_DOCUMENT].map((query) =>
            renderQuery(query),
          )}
        </>,
      );
    }
    if (types.includes(CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS)) {
      queriesJSX.push(
        <>
          <Typography
            style={{
              color: themmeColor.black60,
              fontWeight: 600,
              fontSize: 16,
              marginBlock: 12,
            }}>
            Review Deductions
          </Typography>
          {filteredQueries[DerivedQueryType.HIGH_VALUE_DEDUCTION].map(
            (query) => renderQuery(query),
          )}
        </>,
      );
    }

    if (types.includes(CreateQueryDrawerTab.TAX_PROFILE)) {
      const {sectionNames, grouped} = getTaxProfileQueriesGroupedBySection();
      queriesJSX.push(
        <div>
          {sectionNames.map((sectionName) => (
            <div key={sectionName}>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: themmeColor.black60,
                  marginBottom: 10,
                }}>
                {sectionName}
              </Typography>
              {grouped[sectionName].map((query) => renderQuery(query))}
            </div>
          ))}
        </div>,
      );
    }

    return queriesJSX;
  };

  return (
    <Box
      col
      style={{
        padding: 20,
        height: '100%',
        width: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          style={{
            fontWeight: 800,
            fontSize: 16,
          }}>
          Action required ({getQueriesCount()})
        </Typography>
        {!dontAllowAdd && (
          <DSButton
            type="secondary"
            onClick={() => {
              dispatch(
                setAppState(AppReducerStates.createQueryDrawerStatus, {
                  isVisible: true,
                  selectedTab: types[0],
                  selectedDropdown: null,
                }),
              );
              onClickAddSideEffect();
            }}
            text={'Add'}
          />
        )}
      </div>
      {loaded ? (
        <div style={{overflowY: 'auto', paddingRight: 4}}>
          {renderQuerySection()}
        </div>
      ) : (
        <div style={{marginTop: 12, overflowY: 'auto'}}>
          <Skeleton height={150} width={'100%'} />
          <Skeleton height={150} width={'100%'} />
          <Skeleton height={150} width={'100%'} />
          <Skeleton height={150} width={'100%'} />
        </div>
      )}
    </Box>
  );
};

export default ActionRequired;

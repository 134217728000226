import _ from 'lodash';
import React from 'react';
import SimpleKeyValueField from './SimpleKeyValueField';
import {Typography} from '@mui/material';

interface MultiKeyValueFieldProps {
  fields: {
    name: string;
    path: string;
    style?: React.CSSProperties;
  }[];
  data: any;
  loading?: boolean;
}

const MultiKeyValueField = ({
  fields,
  data,
  loading = false,
}: MultiKeyValueFieldProps) => {
  return (
    <div>
      {fields.map((field) => {
        const value = _.get(data, field.path);

        if (
          typeof value === 'object' &&
          value != null &&
          !Array.isArray(value)
        ) {
          return (
            <div style={field.style}>
              <Typography>{field.name}</Typography>
              <div>
                {Object.entries(value).map(([key, val]) => {
                  return (
                    <SimpleKeyValueField
                      key={key}
                      name={key}
                      value={val}
                      loading={loading}
                    />
                  );
                })}
              </div>
            </div>
          );
        }
        return (
          <SimpleKeyValueField
            key={field.path}
            name={field.name}
            value={value}
            style={field.style}
            loading={loading}
          />
        );
      })}
    </div>
  );
};

export default MultiKeyValueField;

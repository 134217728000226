import {getFormData} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import {
  generateDocUploadURL,
  getOcrData,
  postDocument,
  submitDocForOCR,
} from 'src/appApi';
import {ReturnType} from 'src/store/threads/threads.reducer';
import useCurrentUserId from './useCurrentUserId';
import {TaxReturnDocumentType} from 'src/store/taxReturns/taxReturns.reducer';
import {pollRequest} from 'src/common/utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const useUploadDocument = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();

  const uploadTaxReturn = async (file: any) => {
    // Generate upload url
    const {data} = await generateDocUploadURL({
      filename: file.name,
      year: activeYear,
    });

    // Upload document
    await postDocument(data.url, getFormData(file, data.fields));

    // Submit document for ocr
    const {data: job_id} = await submitDocForOCR({
      s3_key: data.s3_key,
      doc_type: ReturnType.TAX_RETURNS,
    });

    // wait for OCR to complete
    await pollRequest(
      async () => {
        const pollResponse = await getOcrData({
          job_id,
          doc_type: TaxReturnDocumentType.TAX_RETURNS,
          end_user_id: userId,
          year: activeYear,
        });
        return pollResponse;
      },
      (res: any) => {
        return res?.data?.status === 'Done';
      },
      5000,
    );
  };

  return {
    uploadTaxReturn,
  };
};

export default useUploadDocument;

import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import {
  EFILE_STATUS_INFO,
  TaxReturn,
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import useTaxReturnStep from 'src/CpaCenterV2/hooks/useTaxReturnStep';
import Steps from 'src/DesignSystem/Steps/Steps';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import ActivityTable from '../../common/ActivityTable/ActivityTable';
import TickIcon from 'src/icons/TickIcon';
import RightChveron from 'src/icons/RightChveron';
import {useDispatch, useSelector} from 'react-redux';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectThreads} from 'src/store/threads/threads.selector';
import {ThreadStatus} from 'src/store/threads/threads.reducer';
import {selectQueriesReducer} from 'src/store/queries/queries.selector';
import {isQueryResolvedByCpaOrTerminallyResolved} from 'src/store/queries/queries.utils';
import {QuerySubType} from 'src/store/queries/queries.reducer';
import {selectEfileLogs} from 'src/store/efileLogs/efileLogs.selector';
import ReturnExpired from './ReturnExpired';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {getFilteredQueries} from 'src/CpaCenterV2/UserQueries/UserQueries.utils';
import PaperFilePopup from './PaperFilePopup';
import {selectUser} from 'src/store/user/user.selector';

const PAPER_FILE_ALLOWED_STATUSES = [
  EFILE_STATUS_INFO.REJECTED,
  EFILE_STATUS_INFO.NOT_EFILED,
];

const RenderHeadingAndButtonCard = ({
  heading = '',
  buttonTitle = '',
  onClick = () => {},
}: {
  heading: string;
  buttonTitle: string;
  onClick: () => void;
}) => {
  return (
    <Box
      col
      backgroundColor={themmeColor.cpaCenterV2Bg}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingInline: 40,
        paddingBlock: 40,
      }}>
      <Typography
        style={{fontWeight: 600, textAlign: 'center', marginBottom: 16}}>
        {heading}
      </Typography>
      <DSButton
        style={{width: '50%'}}
        type="primary"
        text={buttonTitle}
        onClick={onClick}
      />
    </Box>
  );
};

const RenderCurrentInfo = ({
  step,
  returnId,
}: {
  step: TaxReturnStatus;
  returnId: number;
}) => {
  const dispatch = useDispatch();
  const {navigateToTaxReturnDetailedView, navigateToUserQueries} =
    useCpaCenterV2Navigation();
  const {threads} = useSelector(selectThreads);
  const {queries} = useSelector(selectQueriesReducer);
  const {efileLogs} = useSelector(selectEfileLogs);
  const {taxReturns} = useSelector(selectTaxReturnsReducer);
  const {userId} = useSelector(selectUser);

  const [showPaperFilePopup, setShowPaperFilePopup] = useState(false);

  const currentTaxReturn = taxReturns.find(
    (taxReturn) => taxReturn.return_id === returnId,
  );

  const currentReturnEfileLogs = efileLogs[returnId] ?? [];
  const openNotesCount = threads.filter(
    (thread) => thread.status === ThreadStatus.OPEN,
  ).length;
  const openReviewEsignQueryCount = queries.filter(
    (query) =>
      query.querySubtype === QuerySubType.REVIEW_ESIGN &&
      !isQueryResolvedByCpaOrTerminallyResolved(query),
  ).length;

  const openQueryCount = getFilteredQueries(queries).filter(
    (query) => !isQueryResolvedByCpaOrTerminallyResolved(query),
  ).length;

  const allowPaperFiling = Object.values(
    currentTaxReturn?.efile_status_info ?? {},
  ).reduce((prev, efileStatus) => {
    return prev || PAPER_FILE_ALLOWED_STATUSES.includes(efileStatus);
  }, false);

  const paperFilingOptions = Object.keys(
    currentTaxReturn?.efile_status_info ?? {},
  ).filter((key) =>
    PAPER_FILE_ALLOWED_STATUSES.includes(
      currentTaxReturn?.efile_status_info[key],
    ),
  );

  const navigateToDetailedView = () => {
    navigateToTaxReturnDetailedView(returnId, step);
  };

  switch (step) {
    case TaxReturnStatus.ADD_RETURN: {
      return (
        <RenderHeadingAndButtonCard
          heading="Upload return to get started"
          buttonTitle="Start"
          onClick={navigateToDetailedView}
        />
      );
    }
    case TaxReturnStatus.REVIEW_SUMMARY: {
      return (
        <>
          <ReturnExpired returnId={returnId} />
          <RenderHeadingAndButtonCard
            heading="Review return summary"
            buttonTitle="Start"
            onClick={navigateToDetailedView}
          />
        </>
      );
    }
    case TaxReturnStatus.CPA_REVIEW: {
      return (
        <>
          <ReturnExpired returnId={returnId} />
          <RenderHeadingAndButtonCard
            heading={`You have ${openNotesCount} pending notes`}
            buttonTitle="Continue"
            onClick={navigateToDetailedView}
          />
        </>
      );
    }
    case TaxReturnStatus.REVIEW_ESIGN: {
      return (
        <div style={{display: 'flex'}}>
          <div>
            <ReturnExpired returnId={returnId} />
            <RenderHeadingAndButtonCard
              heading={`${openReviewEsignQueryCount} open queries`}
              buttonTitle="Continue"
              onClick={navigateToDetailedView}
            />
          </div>
          <ActivityTable
            logs={currentTaxReturn?.esign_status_log.map((log) => ({
              event: log.title,
              date: log.date,
              type: log.completed
                ? ActivityTable.TYPE.DONE
                : ActivityTable.TYPE.PENDING,
            }))}
          />
        </div>
      );
    }
    case TaxReturnStatus.EFILE_STATUS: {
      return (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div style={{flex: 0.5}}>
              <Box
                borderColor={themmeColor.offWhite}
                hoverEffect
                onClick={() =>
                  dispatch(
                    setAppState(AppReducerStates.paymentDetailsDrawer, {
                      isVisible: true,
                      returnId,
                    }),
                  )
                }
                hoverColor={themmeColor.focusedBlue}
                style={{
                  paddingBlock: 12,
                  paddingInline: 16,
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  marginTop: 20,
                }}>
                <Typography style={{fontWeight: 600}}>
                  Payment details
                </Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <TickIcon />
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 600,
                      marginRight: 10,
                      marginLeft: 4,
                    }}>
                    Submitted
                  </Typography>
                  <RightChveron />
                </div>
              </Box>
              {allowPaperFiling && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                  }}>
                  <Typography style={{fontWeight: 400, fontSize: 16}}>
                    If you want to request the user to paper file.
                  </Typography>
                  <DSButton
                    type="secondary"
                    text="Request user to paper file "
                    onClick={() => setShowPaperFilePopup(true)}
                    style={{marginTop: 4}}
                  />
                </div>
              )}
              {openQueryCount > 0 && (
                <div style={{marginTop: 20}}>
                  <RenderHeadingAndButtonCard
                    heading={`${openQueryCount} open queries`}
                    buttonTitle="Continue"
                    onClick={navigateToUserQueries}
                  />
                </div>
              )}
            </div>
            <div style={{flex: 0.4}}>
              <Typography
                style={{fontSize: 20, fontWeight: 600, marginBottom: 8}}>
                {currentReturnEfileLogs.length} Efile Logs
              </Typography>
              <ActivityTable
                logs={currentReturnEfileLogs.map((log) => ({
                  event: log.title,
                  date: log.date,
                  type: log.warning
                    ? ActivityTable.TYPE.WARNING
                    : ActivityTable.TYPE.DONE,
                }))}
              />
            </div>
          </div>
          <PaperFilePopup
            isOpen={showPaperFilePopup}
            onClose={() => setShowPaperFilePopup(false)}
            options={paperFilingOptions}
            userId={userId}
          />
        </>
      );
    }
  }
  return null;
};

interface ReturnStatusCardProps {
  taxReturn: TaxReturn;
}

const ReturnStatusCard = ({taxReturn}: ReturnStatusCardProps) => {
  const {taxReturnSteps, currentStep} = useTaxReturnStep(taxReturn.return_id);
  const [currentSelectedStep, setCurrentSelectedStep] = useState(currentStep);

  return (
    <Box col style={{paddingBlock: 12, paddingInline: 20, maxWidth: '70%'}}>
      <Typography style={{fontWeight: 600, marginBottom: 20}}>
        {taxReturn.return_type === TaxReturnType.INDIVIDUAL
          ? 'Individual tax return'
          : 'Business Return'}
      </Typography>
      <Steps
        data={taxReturnSteps}
        onClick={(item) => setCurrentSelectedStep(item.props.step)}
      />
      <div style={{marginTop: 20}}>
        <Typography style={{fontWeight: 600}}>
          {currentSelectedStep}
        </Typography>
        <RenderCurrentInfo
          returnId={taxReturn.return_id}
          step={currentSelectedStep}
        />
      </div>
    </Box>
  );
};

export default ReturnStatusCard;

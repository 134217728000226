import React, {useEffect, useRef, useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {patchQueryStatus, postMessage, resolveQuery} from 'src/appApi';
import {AuthorType, CPAQuestionMessageType} from 'src/constants/constants';
import MessageTextBox from 'src/CpaCenterV2/common/MessageTextBox/MessageTextBox';
import useCurrentQueryId from 'src/CpaCenterV2/hooks/useCurrentQueryId';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import Skeleton from 'src/icons/Skeleton';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {
  DerivedMessageType,
  QueryStatus,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import {
  selectQueriesReducer,
  selectQuery,
} from 'src/store/queries/queries.selector';
import Message from './Message/Message';
import MessageListStickyHeader from './MessageListStickyHeader';
import MessageListHeader from './MessageListHeader';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {MessageListProvider, useMessageList} from './useMessageList';
import MessageListStickyFooter from './MessageListStickyFooter';

const MessageListContent = ({offset}: {offset: number}) => {
  const {userId} = useCurrentUserId();
  const {queryId} = useCurrentQueryId();
  const {notify} = useNotify();
  const dispatch = useDispatch();
  const latestMessageRef = useRef<HTMLDivElement | null>(null);
  const query = useSelector((state: ReduxQueryType) =>
    selectQuery(state, queryId),
  );
  const {loaded} = useSelector(selectQueriesReducer);

  const {
    setMessage,
    isLoading,
    setIsLoading,
    setDraftEditMode,
    setHasClickedOnReopen,
  } = useMessageList();

  useEffect(() => {
    setHasClickedOnReopen(false);
  }, [queryId, query?.queryStatus]);

  useEffect(() => {
    if (query && queryId) {
      latestMessageRef.current?.scrollIntoView?.({
        behavior: 'auto',
        block: 'start',
      });
    }
    setMessage('');
    setDraftEditMode({
      isDraftEditMode: false,
      draftMessageId: -1,
    });
  }, [queryId, query]);

  const {
    spaceLeftBelowTaxFilingSteps,
    USER_QUERIES_SECTION_BAR,
    MESSAGE_STICKY_HEADER_HEIGHT,
    messageListFooterHeight,
  } = useLayout();

  if (queryId === null) {
    return null;
  }

  const onResolve = async () => {
    try {
      setIsLoading(true);
      if (query.queryStatus === QueryStatus.USER_RESOLVED) {
        await resolveQuery({query_id: queryId});
      } else {
        await patchQueryStatus({
          query_id: queryId,
          status: QueryStatus.CPA_RESOLVED,
        });
      }
      await dispatch(fetchQueries({userId}));
      notify(`Query ${queryId} is resolved`, NotificationType.success);
    } catch (e) {
      notify(`failed to resolve query ${queryId}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const {messages = []} = query ?? {};

  const renderSkeleton = (end?: boolean) => {
    return (
      <Skeleton
        width={'30vw'}
        height={65}
        style={{
          justifyContent: end ? 'end' : 'start',
          display: 'flex',
          marginBottom: 20,
        }}
      />
    );
  };

  return (
    <div style={{flex: 1}}>
      <MessageListStickyHeader onResolve={onResolve} isLoading={isLoading} />
      <div
        style={{
          padding: 20,
          overflowY: 'auto',
          height:
            offset - MESSAGE_STICKY_HEADER_HEIGHT - messageListFooterHeight,
        }}>
        <div>
          {!loaded ? (
            <div>
              {renderSkeleton(true)}
              {renderSkeleton()}
              {renderSkeleton(true)}
              {renderSkeleton()}
            </div>
          ) : (
            <div>
              <MessageListHeader />
              {query?.messages?.map((message, index) => (
                <div
                  key={message.messageId}
                  style={{
                    marginBottom: index !== messages.length - 1 ? 20 : 0,
                  }}
                  ref={
                    index === messages.length - 1
                      ? latestMessageRef
                      : undefined
                  }>
                  <Message message={message} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <MessageListStickyFooter />
    </div>
  );
};

const MessageList = ({offset}: {offset: number}) => {
  return (
    // Adding key here is causing a flicker (adding key: queryId)
    <MessageListProvider>
      <MessageListContent offset={offset} />
    </MessageListProvider>
  );
};

export default MessageList;

import {getAllThreadsV2} from 'src/appApi';
import {SET_THREADS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {ThreadsStateType} from './threads.reducer';
import {parseSingleThread} from './threads.utils';
import {selectActiveYear} from '../globals/globals.selector';
import {getStateType} from '../store';

const setThreads = (payload: Pick<ThreadsStateType, 'threads'>) => {
  return {
    type: SET_THREADS,
    payload,
  };
};

export const fetchThreads = (userId: number) => {
  return async (dispatch: any, getState: getStateType) => {
    const activeYear = selectActiveYear(getState());
    try {
      const {data: rawThreads} = await getAllThreadsV2({
        fly_user_id: userId,
        year: activeYear,
      });
      const threads = rawThreads.map(parseSingleThread);
      dispatch(setThreads({threads}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to get threads (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};

import {Typography} from '@mui/material';
import React, {useState} from 'react';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import DSButton from 'src/DesignSystem/Button/Button';
import OcrDataSection from './components/OcrDataSection';
import {
  OcrDataSectionName,
  TaxReturnStatus,
} from 'src/store/taxReturns/taxReturns.reducer';
import {stateAbbreviationNameMap} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';
import {useDispatch, useSelector} from 'react-redux';
import {selectUser} from 'src/store/user/user.selector';
import {sendDocForCpaReview} from 'src/appApi';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import {BACKEND_RESPONSE_KEYS} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import StateSelectionPopup from './components/StateSelectionPopup';
import {
  convertFrontendTaxFormToBackendTaxForm,
  getEmptyTaxReturnSummary,
  isValidTaxReturnForm,
  mapBackendResponseOnEmptyForm,
} from './ReviewSummary.utils';
import _ from 'lodash';
import {ReturnSummaryInputFieldProps} from './ReviewSummary.types';

const ReviewSummary = ({url}: {url: string | null}) => {
  const {returnId, currentReturn, taxReturnsLoaded} = useCurrentTaxReturnId();
  const {userId} = useCurrentUserId();
  const {taxFilingId, loaded: userLoaded} = useSelector(selectUser);
  const [federalOcrData, setFederalOcrData] = useState<any>(() => {
    const backendFederalSummary =
      currentReturn.ocr_data[OcrDataSectionName.federal_return_summary];
    return mapBackendResponseOnEmptyForm(backendFederalSummary);
  });
  const [stateOcrData, setStateOcrData] = useState(() => {
    const backendStateReturnSummaryData =
      currentReturn.ocr_data[OcrDataSectionName.states_return_summary];
    const mappedStateReturnForm: {[key: string]: any} = {};
    Object.entries(backendStateReturnSummaryData).forEach(([key, value]) => {
      mappedStateReturnForm[key] = mapBackendResponseOnEmptyForm(value);
    });
    return mappedStateReturnForm;
  });
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();
  const {spaceLeftBelowTaxReturnSection, TAX_RETURN_BOTTOM_BAR} = useLayout();
  const [stateSelectionPopup, setStateSelectionPopup] = useState(false);

  const openSelectStatePopup = () => {
    setStateSelectionPopup(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();
  const dispatch = useDispatch();

  const statesAlreadyAdded = Object.keys(stateOcrData);

  const getDataWithUpdatedKey = (
    data: ReturnSummaryInputFieldProps[],
    toUpdateFieldIndex: number,
    newKey: string,
  ) => {
    if (!newKey) {
      return data;
    }
    const newData = _.cloneDeep(data);
    return newData.map((field, index) => {
      if (index === toUpdateFieldIndex) {
        return {
          ...field,
          key: newKey,
        };
      }
      return field;
    });
  };

  const getDataWithUpdatedValue = (
    data: ReturnSummaryInputFieldProps[],
    toUpdateFieldIndex: number,
    newValue: any,
  ) => {
    const newData = _.cloneDeep(data);
    return newData.map((field, index) => {
      if (index === toUpdateFieldIndex) {
        return {
          ...field,
          value: newValue ? Number(newValue) : null,
        };
      }
      return field;
    });
  };

  const onAddState = (newState: string | null) => {
    if (!newState) return;
    setStateOcrData((prev) => {
      const emptyStateTaxReturn = getEmptyTaxReturnSummary();
      return {
        ...prev,
        [newState]: emptyStateTaxReturn,
      };
    });
  };

  const onDeleteState = (newState: string) => {
    const newStateOcrData: Object = _.cloneDeep(stateOcrData);
    delete newStateOcrData[newState];
    setStateOcrData(newStateOcrData);
  };

  const onSubmitForCpaReview = async () => {
    try {
      setIsLoading(true);
      const backendFederalOcrData =
        convertFrontendTaxFormToBackendTaxForm(federalOcrData);
      const backendStateOcrData: any = {};
      Object.keys(stateOcrData).forEach((state) => {
        backendStateOcrData[state] = convertFrontendTaxFormToBackendTaxForm(
          stateOcrData[state],
        );
      });
      await sendDocForCpaReview(
        {tax_filing_id: taxFilingId},
        {
          [BACKEND_RESPONSE_KEYS.STATUS]: TAX_FILING_STATUS.SUMMARY_REVIEWED,
          [OcrDataSectionName.federal_return_summary]: backendFederalOcrData,
          [OcrDataSectionName.states_return_summary]: backendStateOcrData,
        },
      );
      await dispatch(fetchTaxReturns(userId));
      navigateToTaxReturnDetailedView(returnId, TaxReturnStatus.CPA_REVIEW);
      notify('Sent return for cpa review', NotificationType.success);
    } catch (e) {
      notify(`Failed to send for cpa review ${e}`, NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!url) {
    return null;
  }

  return (
    <div>
      <FullScreenLoading open={isLoading} />
      <div style={{display: 'flex'}}>
        <iframe
          title="Review-esign-return"
          src={url}
          style={{width: '70%', height: spaceLeftBelowTaxReturnSection}}
        />
        <div
          style={{
            height: spaceLeftBelowTaxReturnSection,
            paddingInline: 20,
            width: '30%',
          }}>
          <div
            style={{
              height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
              overflowY: 'auto',
            }}>
            <OcrDataSection
              sectionName="Federal"
              onChangeKey={(index, newKey) => {
                const updatedData = getDataWithUpdatedKey(
                  federalOcrData,
                  index,
                  newKey,
                );
                setFederalOcrData(updatedData);
              }}
              onChangeValue={(index, newValue) => {
                const updatedData = getDataWithUpdatedValue(
                  federalOcrData,
                  index,
                  newValue,
                );
                setFederalOcrData(updatedData);
              }}
              data={federalOcrData}
            />
            {Object.entries(stateOcrData).map(([state, stateData]) => {
              return (
                <OcrDataSection
                  key={state}
                  sectionName={`${stateAbbreviationNameMap[state]}`}
                  onChangeKey={(index, newKey) => {
                    const updatedStateData = getDataWithUpdatedKey(
                      stateData,
                      index,
                      newKey,
                    );
                    setStateOcrData({
                      ...stateOcrData,
                      [state]: updatedStateData,
                    });
                  }}
                  onChangeValue={(index, newValue) => {
                    const updatedStateData = getDataWithUpdatedValue(
                      stateData,
                      index,
                      newValue,
                    );
                    setStateOcrData({
                      ...stateOcrData,
                      [state]: updatedStateData,
                    });
                  }}
                  data={stateData}
                  onDelete={() => onDeleteState(state)}
                />
              );
            })}
            <div
              style={{
                marginTop: 12,
                display: 'flex',
                justifyContent: 'center',
              }}>
              <DSButton
                type="primary"
                text="+ Add state"
                onClick={openSelectStatePopup}
                style={{paddingInline: 20, paddingBlock: 8}}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
              height: TAX_RETURN_BOTTOM_BAR,
              paddingBlock: 8,
            }}>
            <DSButton
              type="primary"
              onClick={onSubmitForCpaReview}
              style={{width: '100%', paddingInline: 32, paddingBlock: 12}}
              text="Submit for Cpa Review"
              disabled={
                !taxReturnsLoaded ||
                !userLoaded ||
                !isValidTaxReturnForm(federalOcrData) ||
                // disabled if atleast one state return form is invalid
                Object.entries(stateOcrData).some(
                  ([state, form]) => !isValidTaxReturnForm(form),
                )
              }
            />
          </div>
        </div>
      </div>
      <StateSelectionPopup
        isOpen={stateSelectionPopup}
        onClose={() => setStateSelectionPopup(false)}
        onConfirm={onAddState}
        statesNotAllowed={statesAlreadyAdded}
      />
    </div>
  );
};

export default ReviewSummary;

import {Typography} from '@mui/material';
import _ from 'lodash';
import React, {ReactNode} from 'react';
import {themmeColor} from 'src/constants/constants';
import RightChveron from 'src/icons/RightChveron';
import {getFieldValue} from '../CpaCenterV2.utils';

export enum TABLE_DATA_TYPE {
  HEADING = 'HEADING',
}

interface KeyValueTableProps {
  data: any[];
  keys: {
    getValue: (rowData: any, index: number) => void;
    textStyle?: React.CSSProperties;
    cellStyle?: React.CSSProperties;
    onClick?: (rowData: any, index: number) => void;
    getIcon?: (rowData: any, index: number) => ReactNode;
  }[];
}

const KeyValueTable = ({data, keys}: KeyValueTableProps) => {
  return (
    <div
      style={{
        backgroundColor: themmeColor.cpaCenterV2Bg,
        borderRadius: 12,
        borderStyle: 'solid',
        borderColor: themmeColor.offWhite,
        borderWidth: 1,
      }}>
      {data.map((rowData: any, rowIndex: number) => {
        const isLast = data.length - 1 === rowIndex;
        return (
          <div
            key={rowIndex}
            style={{
              display: 'flex',
              paddingBlock: 12,
              paddingInline: 16,
              justifyContent: 'space-between',
              borderBottomStyle: isLast ? 'none' : 'solid',
              borderBottomColor: themmeColor.offWhite,
              borderBottomWidth: isLast ? 0 : 1,
            }}>
            {/* Row */}
            {keys.map(
              (
                {textStyle = {}, getValue, cellStyle = {}, onClick, getIcon},
                index,
              ) => {
                const value = getValue(rowData, index);
                return (
                  <div
                    onClick={
                      onClick ? () => onClick(rowData, index) : undefined
                    }
                    key={`${value}-${index}`}
                    style={{flex: 1, ...cellStyle}}>
                    {/* Cell */}
                    <Typography style={{...textStyle}}>
                      {getFieldValue(value)}
                    </Typography>
                    {getIcon && getIcon(rowData, index)}
                    {onClick && (
                      <div style={{display: 'flex'}}>
                        <RightChveron />
                      </div>
                    )}
                  </div>
                );
              },
            )}
          </div>
        );
      })}
    </div>
  );
};

export default KeyValueTable;

import axios, {AxiosResponse} from 'axios';
import {
  AuthorType,
  CPAQuestionMessageType,
  CPAQuestionType,
  LocalStorageKey,
  QUERY_STATUS,
  VALID_DOCUMENT_REASON,
  VALID_DOCUMENT_STATUS,
  TaxProfileSubsection,
  CPA_ACK_TEMPLATE_NAME,
  TAX_EXTENSION_STATUS,
  VALID_CPA_EMAILS,
} from './constants/constants';
import {
  BACKEND_RESPONSE_KEYS,
  EXTENSION_STATUS,
  USER_ACTIVITY_TYPE,
} from './CpaCenterList/components/cpaList.utils';
import {CCH_ACTION_BUTTONS} from './CpaCenterUserInfo/CpaCenterCCH/cch.utils';
import {
  aggregateDocumentsActionCount,
  backendDocumentsResponseMapper,
} from './CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import {ReviewEsignDocumentType} from './CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsignContext';
import {
  aggregateDocumentUnreadThreads,
  aggregateReviewEsignActionCount,
  backendReviewAndEsignResponseMapper,
  CPA_REVIEW_THREAD_STATUS,
  CpaReviewThread,
  EsignFields,
  ReviewOcrValues,
} from './CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import {
  aggregateTaxQueriesActionCount,
  taxQueriesBackendResponseMapper,
} from './CpaCenterUserInfo/CpaCenterTaxQueries/components/taxqueries.utils';
import {BackendQuery} from 'src/types/query.types';
import {
  BackendBusinessDetails,
  BackendStateReturnsInfo,
  BackendTaxForm,
  BackendTransaction,
  QtcInputData,
  ResponseCCHStatus,
  TaxFilingDocs,
  TaxFilingStatus,
} from './appApi.types';
import {TaxProfileReviewStatus} from './store/taxProfileStatus/taxProfileStatus.reducer';
import {QuerySubType, QueryType} from './store/queries/queries.reducer';
import {TAX_PROFILE_FIELDS} from './CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {TF_SEGMENT} from './store/user/user.reducer';
import {
  BOI_FILING_COLUMNS,
  BOI_STATUS,
  BoiBusinessForm,
  BoiFilingData,
} from './BoiFilingCenter/types';

const getConfig = (params = {}, authTokenNotReqd = false) => {
  const token = localStorage.getItem(LocalStorageKey.Token);
  return {
    baseURL: process.env.REACT_APP_DATA_PROVIDER_URL,
    headers: authTokenNotReqd
      ? {}
      : {
          Authorization: `Token ${token}`,
        },
    params,
    paramsSerializer: {
      indexes: null,
    },
  };
};

const axiosGet = <T extends any>(
  url: string,
  params = {},
  authTokenNotReqd = false,
) => {
  const instance = getConfig(params, authTokenNotReqd);
  return axios.get<T>(url, instance);
};

const axiosPost = (
  url: string,
  body: any,
  params = {},
  authTokenNotReqd = false,
) => {
  const instance = getConfig(params, authTokenNotReqd);
  return axios.post(url, body, instance);
};

const axiosPatch = (
  url: string,
  params = {},
  body = {},
  authTokenNotReqd = false,
) => {
  const instance = getConfig(params, authTokenNotReqd);
  return axios.patch(url, body, instance);
};

const axiosDelete = (
  url: string,
  params = {},
  authTokenNotRequired = false,
) => {
  const instance = getConfig(params, authTokenNotRequired);
  return axios.delete(url, instance);
};

export const postAuthToken = (id_token: string) => {
  return axiosPost(
    '/users/',
    {
      provider: 'GMAIL',
      id_token,
      is_data_policy_accepted: true,
    },
    {},
    true,
  );
};

export const getUsersList = (params = {}) => {
  return axiosGet(
    '/dashboard/tax_engine/tax_filing_status/get_users/',
    params,
  );
};

export const getUserDocuments: (params: {
  user: number | string;
  year: number;
}) => Promise<AxiosResponse<TaxFilingDocs, any>> = (params) => {
  return axiosGet('dashboard/tax_docs/all_docs/', params);
};

export const getReviewEsignDocuments: (params: {
  user: number | string;
  year: number;
}) => Promise<AxiosResponse<any, any>> = (params) => {
  return axiosGet('dashboard/tax_docs/returns_esign_docs/', params);
};

export const getDocument: (
  link: string,
) => Promise<AxiosResponse<any, any>> = (link) => {
  return axiosGet(link);
};

export const postDocumentStatus: (body: {
  tfd_info_id: number;
  status: VALID_DOCUMENT_STATUS;
  reason?: VALID_DOCUMENT_REASON | any;
}) => Promise<AxiosResponse<any, any>> = (body) => {
  return axiosPost('dashboard/tax_docs/update_review_status/', body);
};

export const getQueriesList = (params: {
  type?: Array<CPAQuestionType>;
  app_year?: Array<number> | number;
  page?: number | string;
  per_page?: number | string;
  ordering?: string;
  fly_user_id?: number | string;
  is_conversation_required?: boolean;
  fly_user_name?: string;
  fly_user_email?: string;
  date_from?: string;
  date_to?: string;
  status?: string;
  assignee?: string;
  rated_conversation?: boolean;
  id?: string | number;
}) => {
  return axiosGet<{
    count: number;
    next: string | null;
    previous: string | null;
    results: BackendQuery[];
  }>('dashboard/cpa_question/', params);
};

export const postTaxQuery: (params: {
  fly_user_id: string | number;
  query?: string;
  app_year: number;
  type?: string;
  sub_type?: string | null;
  tfd_info_id?: number;
  tfd_tag_id?: number;
  extra_data?: any;
  doc_type?: string;
  description?: string;
  section?: string;
  txn_ids?: number[];
  is_draft?: boolean;
}) => Promise<AxiosResponse<any, any>> = (body) => {
  return axiosPost('dashboard/cpa_question/', body);
};

export const postDraftMessage: (params: {
  draft_id?: number;
  is_terminal?: boolean;
}) => Promise<AxiosResponse<any, any>> = (body) => {
  return axiosPost('/dashboard/cpa_question_message/send_draft/', body);
};

export const postMessage: (params: {
  message?: string;
  cpa_question_id: number | string;
  author_type: AuthorType | any;
  type: CPAQuestionMessageType | any;
  doc_type?: string;
  is_draft?: boolean;
  is_terminal?: boolean;
}) => Promise<AxiosResponse<any, any>> = (body) => {
  return axiosPost('/dashboard/cpa_question_message/', body);
};

export const patchCpaQueryMessage = (
  messageId: number,
  data: {message: string},
) => {
  return axiosPatch(`/dashboard/cpa_question_message/${messageId}/`, {}, data);
};

export const deleteCpaQueryMessage = (messageId: number) => {
  return axiosDelete(`/dashboard/cpa_question_message/${messageId}/`);
};

export const patchQueryStatus = (params: {
  query_id: number | string;
  status: any;
}) => {
  return axiosPatch(
    `dashboard/cpa_question/${params.query_id}/`,
    {},
    {
      status: params.status,
    },
  );
};

export const resolveQuery: (params: {
  query_id: number | string;
}) => Promise<AxiosResponse<any, any>> = (params) => {
  return axiosPatch(
    `dashboard/cpa_question/${params.query_id}/`,
    {},
    {
      status: QUERY_STATUS.RESOLVED,
    },
  );
};

export const reOpenQuery: (params: {
  query_id: number | string;
}) => Promise<AxiosResponse<any, any>> = (params) => {
  return axiosPatch(
    `dashboard/cpa_question/${params.query_id}/`,
    {},
    {
      status: QUERY_STATUS.OPEN,
    },
  );
};

export const patchCpaQuery = (
  queryId: number,
  data: {
    status?: QUERY_STATUS;
    assignee?: string;
  },
) => {
  return axiosPatch(`dashboard/cpa_question/${queryId}/`, {}, data);
};

export const getUserInfo: (params: {
  fly_user_id: number | string;
  year: number;
}) => Promise<AxiosResponse<TaxFilingStatus, any>> = (params) => {
  return axiosGet('/dashboard/tax_engine/tax_filing_status/', params);
};

export const patchTaxFiling: (
  params: {
    tax_filing_id: number | string;
  },
  body: {
    [BACKEND_RESPONSE_KEYS.STATUS]?: number;
    [BACKEND_RESPONSE_KEYS.NOTE]?: string;
    [BACKEND_RESPONSE_KEYS.NEXT_ACTION_DATE]?: string;
    [BACKEND_RESPONSE_KEYS.CPA_FACING_RETURN_STATUS]?: number;
    [BACKEND_RESPONSE_KEYS.EXTENSION_STATUS]?: EXTENSION_STATUS;
    [BACKEND_RESPONSE_KEYS.ACTIVITY]?: USER_ACTIVITY_TYPE;
    [BACKEND_RESPONSE_KEYS.COLLABORATOR_EMAIL]?: VALID_CPA_EMAILS;
  },
) => Promise<AxiosResponse<any, any>> = (params, body) => {
  return axiosPatch(
    `/dashboard/tax_engine/tax_filing_status/${params.tax_filing_id}/`,
    {},
    body,
  );
};

export const getTaxProfile: (params: {
  years: number;
  fly_user_id: number | string;
}) => Promise<AxiosResponse<any, any>> = (params) => {
  return axiosGet(
    '/dashboard/tax_engine/tax_profile/get_tax_profile_v2/',
    params,
  );
};

export const getMergedPDF: (params: {
  year: number;
  flyfin_user_id: string | number;
}) => string = ({flyfin_user_id, year}) => {
  const token = localStorage.getItem(LocalStorageKey.Token);
  const baseURL = process.env.REACT_APP_DATA_PROVIDER_URL;
  return `${baseURL}/dashboard/tax_docs/get_merged_pdf/?user=${flyfin_user_id}&year=${year}&token=${token}`;
};

export interface TabActionCountProps {
  taxProfileActionCount: number;
  documentOCRActionCount: number;
  taxQueriesActionCount: number;
  reviewEsignActionCount: number;
}

export const initTabsActionCount: () => TabActionCountProps = () => {
  return {
    taxProfileActionCount: 0,
    documentOCRActionCount: 0,
    taxQueriesActionCount: 0,
    reviewEsignActionCount: 0,
  };
};

export const getActionCounts: (params: {
  user: number | string;
  year: number;
}) => Promise<TabActionCountProps> = async ({user, year}) => {
  try {
    const response = await Promise.all([
      getUserDocuments({user, year}),
      getQueriesList({
        fly_user_id: user,
        app_year: [year, year + 1],
        type: [CPAQuestionType.TAX_FILING, CPAQuestionType.QTC],
        is_conversation_required: true,
      }),
      getReviewEsignDocuments({user, year}),
    ]).then(async (res) => {
      const r1 = res[0];
      const r2 = res[1];
      const r3 = res[2];

      const documentOCRActionCount = aggregateDocumentsActionCount(
        backendDocumentsResponseMapper(r1.data.forms),
      );
      const taxQueriesActionCount = aggregateTaxQueriesActionCount(
        taxQueriesBackendResponseMapper(r2.data.results),
      );
      let reviewEsignActionCount = 0;
      const reviewEsignDocuments = backendReviewAndEsignResponseMapper(
        r3.data,
      );
      if (reviewEsignDocuments.length !== 0) {
        if (reviewEsignDocuments.length <= 1) {
          const r4 = await getAllDocThreads({
            doc_id: reviewEsignDocuments[0].documentId,
          });
          reviewEsignActionCount = aggregateDocumentUnreadThreads(
            r4.data as CpaReviewThread[],
          );
        } else {
          reviewEsignActionCount = aggregateReviewEsignActionCount(
            reviewEsignDocuments,
          );
        }
      }

      const actionCount = initTabsActionCount();
      return {
        ...actionCount,
        documentOCRActionCount,
        taxQueriesActionCount,
        reviewEsignActionCount,
      };
    });

    return response;
  } catch (e) {
    alert(`Something went wrong while fetching action counts ${e}`);
  }
};

export const updateMissingExpenseStatusToRecieved = (body: {
  user: number | string;
  year: number;
}) => {
  return axiosPatch(
    '/dashboard/transaction/mark_missing_expenses_all_received/',
    {},
    body,
  );
};

export const getMissingExpenseData = (params: {
  user: number | string;
  year: number;
}) => {
  return axiosGet(
    '/dashboard/transaction/get_missing_expenses_csv_logs/',
    params,
  );
};

export const postExpenseFile = (body: {
  records: any;
  user: number | string;
  csv_filename: string;
  insti: number;
}) => {
  return axiosPost('/dashboard/transaction/upload_missing_expenses/', body);
};

export const generateDocUploadURL = (body: {
  filename: string;
  year: number;
}) => {
  return axiosPost('tax_engine/tax_docs_presigned_url/gen_upload_url/', body);
};

export const postDocument = (url: string, body: any) => {
  return axiosPost(url, body, {}, true);
};

export interface BulkCreateDocType {
  doc_type: ReviewEsignDocumentType;
  year: number;
  links: [
    {
      filename: string;
      s3_key?: string;
      extras: {
        jurisdiction: string;
        invite_link?: string;
        sign_now_document_id?: string;
      };
    },
  ];
}

export const bulkCreateReviewEsignDocs = (body: {
  user: number | string;
  year: number;
  doc_data?: Array<BulkCreateDocType>;
  s3_key: string;
  filename: string;
  sign_now_document_id?: string;
  jurisdiction: string;
  states_return_summary: {[state: string]: ReviewOcrValues};
  federal_return_summary: ReviewOcrValues;
  esign_fields?: EsignFields[];
}) => {
  return axiosPost('/dashboard/tax_docs/upload_review_esign_docs/', body);
};

export const reuploadDoc = (body: {
  filename: string;
  s3_key?: string;
  extras: any;
  year: number;
  doc_type: ReviewEsignDocumentType;
  tfd_info_id: number;
  fly_user_id: number | string;
  states_return_summary?: {[state: string]: ReviewOcrValues} | {};
  federal_return_summary?: ReviewOcrValues | {};
  esign_fields: EsignFields[];
}) => {
  return axiosPatch(`/dashboard/tax_docs/update_review_esign_docs/`, {}, body);
};
export const assignCPAToUser = (assigned_cpa: string, end_user_id: number) => {
  return axiosPost('/dashboard/users/assign_cpa/', {
    assigned_cpa,
    end_user_id,
  });
};

export const assignReviewerToUser = (
  assigned_reviewer: string,
  end_user_id: number,
  year: number,
) => {
  return axiosPost('/dashboard/tax_docs/assign_reviewer/', {
    assigned_reviewer,
    end_user_id,
    year,
  });
};

export const updateDocType = (body: {
  tfd_info_id: number;
  fly_user_id: number | string;
  year: number;
  doc_type: string;
}) => {
  return axiosPatch('/dashboard/tax_docs/update_doc_info/', {}, body);
};

export const getDocumentOcrData = (params: {
  fly_user_id: number;
  tfd_info_id: string | number;
}) => {
  return axiosGet('/dashboard/tax_docs/get_doc_ocr_data/', params);
};

export const addTaxForm = (body: {
  fly_user_id: number;
  tfd_info_id: number;
  form_type: string;
}) => {
  return axiosPost('/dashboard/tax_forms/add_new_tax_form/', {}, body);
};

export const updateTaxForm = (body: {
  fly_user_id: number;
  tfd_info_id: string | number;
  forms: any[];
}) => {
  return axiosPatch(`/dashboard/tax_docs/update_doc_ocr_data/`, {}, body);
};

export const deleteTaxForm = (params: {tax_form_id: string | number}) => {
  return axiosDelete(`/dashboard/tax_forms/${params.tax_form_id}/`);
};

export const getPreviewInfo = (params: {
  fly_user_id: string | number;
  year: number;
  sections: CCH_ACTION_BUTTONS;
}) => {
  return axiosGet('/dashboard/cch/get_preview_info/', params);
};

export const postCCHData = (body: {
  fly_user_id: number | string;
  year: number;
  config: {
    [key in CCH_ACTION_BUTTONS]?: {
      should_populate: boolean;
      resources?: {tax_form_ids?: number[]};
    };
  };
}) => {
  return axiosPost('/dashboard/cch/populate_data/', body);
};

export const exportData = (params: {fly_user_id: number; year: number}) => {
  return axiosGet('/dashboard/tax_engine/export_data/export_data/', params);
};

export const getRefundDetails = (params: {fly_user_id: number}) => {
  return axiosGet('/dashboard/users/refund_details/', params);
};

export const submitDocForOCR = (params: {
  s3_key: string;
  doc_type: string;
}) => {
  return axiosPost('/dashboard/ocr/submit_doc_for_ocr/', params);
};

export const getOcrData = (params: {
  job_id: string;
  doc_type: string;
  end_user_id: string | undefined | number;
  year: number;
}) => {
  return axiosGet('/dashboard/ocr/get_ocr_data/', params);
};

export const updateCCHClientId = (
  fly_user_id: number,
  cch_client_id: string,
) => {
  return axiosPatch(
    `/dashboard/users/${fly_user_id}/`,
    {},
    {cch_client_id: cch_client_id},
  );
};

export const postConversationRating = (data: {
  cpa_question_id: number;
  correctness_rating: number;
  clarity_rating: number;
  grammar_rating: number;
  comment: string;
}) => {
  return axiosPost('/dashboard/cpa_response_rating/', data);
};
export const patchConversationRating = ({
  ratingId,
  ...data
}: {
  ratingId: number;
  cpa_question_id: number;
  correctness_rating: number;
  clarity_rating: number;
  grammar_rating: number;
  comment: string;
}) => {
  return axiosPatch(`/dashboard/cpa_response_rating/${ratingId}/`, {}, data);
};

export const getCpaConversationRating = (ratingId: number) => {
  return axiosGet<{
    correctness_rating: number;
    clarity_rating: number;
    grammar_rating: number;
    comment: string;
  }>(`/dashboard/cpa_response_rating/${ratingId}/`);
};

export const getBankDetails = (params: {
  year: number;
  fly_user_id: number | string;
}) => {
  return axiosGet(
    '/dashboard/tax_engine/tax_filing_status/get_bank_details/',
    params,
  );
};

export const listPromoCodes = (
  promo_code: string = '',
  coupon_code: string = '',
  page?: number,
) => {
  return axiosGet('/dashboard/utils/list_coupon_and_promo/', {
    promo_code,
    coupon_code,
    page,
  });
};

export const createPromoCodes = (data: any) => {
  return axiosPost('/dashboard/utils/create_promo_code/', data);
};

export const createCouponCode = (data: any) => {
  return axiosPost('/dashboard/utils/create_coupon_code/', data);
};

export const retrieveUserDetails = (params: {fly_user_id: number}) => {
  return axiosGet('/dashboard/utils/retrieve_user_details/', params);
};

export const updateUserSubscriptionId = (body: {
  fly_user_id: number;
  new_subs_id: string;
}) => {
  return axiosPost('/dashboard/utils/update_subscription_id/', body);
};

export const getAllDocThreads = (params: {doc_id: number | string}) => {
  return axiosGet('/dashboard/cpa_review/get_all_threads/', params);
};

export const addThread = (body: {
  fly_user_id: number;
  year: number;
  comment: string;
}) => {
  return axiosPost('/dashboard/cpa_review/', body);
};

export const addMessage = (body: {
  thread_id: number | string;
  comment: string;
}) => {
  return axiosPost('/dashboard/cpa_review/save_message/', body);
};

export const setThreadStatus = (
  body: {
    status: CPA_REVIEW_THREAD_STATUS;
  },
  params: {
    thread_id: number | string;
  },
) => {
  return axiosPatch(`/dashboard/cpa_review/${params.thread_id}/`, {}, body);
};

export const updateThreadUnreadMessages = (
  body: {
    unread_messages: number[];
  },
  params: {
    thread_id: number | string;
  },
) => {
  return axiosPatch(`/dashboard/cpa_review/${params.thread_id}/`, {}, body);
};

export const sendDocForCpaReview = (
  params: {
    tax_filing_id: number | string;
  },
  body: {
    [BACKEND_RESPONSE_KEYS.STATUS]?: number; // should always be 5
    states_return_summary?: {[state: string]: ReviewOcrValues};
    federal_return_summary?: ReviewOcrValues | {};
  },
) => {
  return axiosPatch(
    `/dashboard/tax_engine/tax_filing_status/${params.tax_filing_id}/`,
    {},
    body,
  );
};

export const sendDocForReviewEsign = (body: {
  user: number | string;
  year: number;
  filename: string;
  jurisdiction?: string;
  sign_now_document_id: string;
}) => {
  return axiosPatch('/dashboard/tax_docs/send_for_review_esign/', {}, body);
};

export const deleteFlyUser = (user_id: number) => {
  const instance = getConfig({}, false);
  return axios.delete('/dashboard/users/delete_user_data/', {
    ...instance,
    data: {user_id},
  });
};

export const getBusinessDetails = (fly_user_id: number, year: number) => {
  return axiosGet<BackendBusinessDetails[]>('/dashboard/business_details/', {
    fly_user_id,
    year,
  });
};

export const getTaxForms = (fly_user_id: number, year: number) => {
  return axiosGet<BackendTaxForm[]>('/dashboard/tax_forms/', {
    fly_user_id,
    year,
  });
};

export const getTransactions = (props: {user_id: number; year: number[]}) => {
  return axiosGet<BackendTransaction[]>('/dashboard/transaction/list_v2/', {
    ...props,
  });
};

export const getStateReturnsInfo = (props: {
  fly_user_id: number;
  year: number;
}) => {
  return axiosGet<BackendStateReturnsInfo>(
    '/dashboard/tax_forms/state_returns_info/',
    props,
  );
};

export const rephraseTextWithGPT = ({message = ''}) => {
  return axiosPost(
    '/website/tax-gpt/',
    {
      is_self_employed: false,
      is_working_from_home: false,
      is_user_query_tax_related: false,
      og_prompt: `We are tax CPA. Please don't mention tax cpa or repharsing in the response. Please help me repharse - ${message}`,
      should_contain_base_prompt: false,
      conversation_thread: [],
      use_get_prompt_func: false,
      temperature: 0.5,
      max_tokens: false,
    },
    {},
    true,
  );
};

export const getTaxProfileStatus = (fly_user_id: number, year: number) => {
  return axiosGet('/dashboard/tax_engine/tax_profile_sections/', {
    fly_user_id,
    year,
  });
};

export const postTaxProfileReviewStatus = (
  fly_user_id: number,
  year: number,
  section_wise_status: {
    [key in TaxProfileSubsection]?: TaxProfileReviewStatus;
  },
) => {
  return axiosPost('/dashboard/tax_engine/tax_profile_sections/', {
    fly_user_id,
    year,
    section_wise_status,
  });
};

export const getScheduleWiseSummary = ({
  fly_user_id,
  year,
}: {
  fly_user_id: number;
  year: number;
}) => {
  return axiosGet('/dashboard/transaction/schedule_wise_deduction_summary/', {
    fly_user_id,
    year,
  });
};

export const getCCHSyncStatus = (fly_user_id: number, year: number) => {
  return axiosGet<ResponseCCHStatus>('/dashboard/cch/get_sync_status_all/', {
    fly_user_id,
    year,
  });
};

export const postCCHStatus = ({
  fly_user_id,
  year,
  config,
}: {
  fly_user_id: number;
  year: number;
  config: any;
}) => {
  return axiosPost('/dashboard/cch/populate_data/', {
    fly_user_id,
    year,
    config,
  });
};

export const getHighValueDeductions = ({user_id, app_year}) => {
  return axiosGet<BackendTransaction[]>(
    '/dashboard/transaction/high_value_deductions/',
    {
      user_id,
      app_year,
    },
  );
};

export const markNotHighValueDeductions = ({
  txn_ids,
  user_id,
}: {
  user_id: number;
  txn_ids: number[];
}) => {
  return axiosPatch(
    '/dashboard/transaction/white_list_high_value_deductions/',
    {},
    {txn_ids, user_id},
  );
};
export const getQtcInputs = (params: {
  fly_user_id: number;
  deduction_option: string;
  year: number;
}) => {
  return axiosGet('/dashboard/tax_engine/calc_income_tax_v3/', params);
};

export const getApplicableDeductions = (params: {
  fly_user_id: number;
  year: number;
  flow_names: string;
}) => {
  return axiosGet(
    `/dashboard/tax_engine/get_tax_profile_flow_wise_data/`,
    params,
  );
};

export const getAllReturns = (params: {fly_user_id: number; year: number}) => {
  return axiosGet('/dashboard/tax_engine/returns/get_all_returns/', params);
};

export const getAllThreadsV2 = (params: {
  fly_user_id: number;
  year: number;
}) => {
  return axiosGet<any>('/dashboard/cpa_review/get_all_threads_v2/', params);
};

export const getEfileLogs = (params: {fly_user_id: number; year: number}) => {
  return axiosGet(
    '/dashboard/tax_engine/returns/get_efile_status_log/',
    params,
  );
};

export const getBankAccountDetails = (params: {
  fly_user_id: number;
  year: number;
}) => {
  return axiosGet('/dashboard/tax_engine/bank_acc_details/', params);
};

export const syncTaxReturnWithCCH = (body: {return_id: number}) => {
  return axiosPost(
    '/dashboard/tax_engine/bank_acc_details/send_to_cch/',
    body,
  );
};
export const postCpaAcknowledgementQuery = ({
  user_ids,
  app_year,
  message,
  template_name,
}: {
  user_ids: number[];
  app_year: number;
  message: string;
  template_name: CPA_ACK_TEMPLATE_NAME;
}) => {
  return axiosPost('/dashboard/cpa_question/send_ack/', {
    user_ids,
    message,
    app_year,
    type: QueryType.TAX_FILING,
    sub_type: QuerySubType.GENERAL,
    template_name,
  });
};

export const getDistributeDeductions = (params: {
  fly_user_id: number;
  year: number;
}) => {
  return axiosGet('/dashboard/exp_alloc/', params);
};

export const postDistributeDeductionsSplit = (body: {
  fly_user_id: number;
  year: number;
  allocation: any;
}) => {
  return axiosPost('/dashboard/exp_alloc/', body);
};
export const updateTaxFilingExtensionStatus = (body: {
  fly_user_id_list: number[];
  year: number;
  tax_extension_status: TAX_EXTENSION_STATUS;
}) => {
  return axiosPatch(
    '/dashboard/tax_engine/tax_filing_status/update_tax_extension_status/',
    {},
    body,
  );
};

export const bulkUpdateTaxProfile = (body: {
  fly_user_id: number;
  year: number;
  questions_data: {
    question_id: TAX_PROFILE_FIELDS;
    answer: any;
  }[];
}) => {
  return axiosPatch(
    '/dashboard/tax_engine/tax_profile/bulk_update/',
    {},
    body,
  );
};

export const exportExpressExtensionData = () => {
  return axiosPost(
    '/dashboard/tax_engine/tax_filing_status/export_tax_extension_data/',
    {},
  );
};

export const updateTFSegment = (body: {
  user_ids: number[];
  segment_name: TF_SEGMENT;
}) => {
  return axiosPost(
    '/dashboard/tax_engine/tax_filing_status/update_or_create_user_segment/',
    body,
  );
};

export const markPaperFiled = (body: {
  fly_user_id: number;
  year: number;
  jurisdictions: string[];
}) => {
  return axiosPatch(
    '/dashboard/tax_engine/tax_filing_status/mark_paper_filed/',
    {},
    body,
  );
};

export const getQtcData = (params: {fly_user_id: number}) => {
  return axiosGet<QtcInputData>(
    '/dashboard/tax_engine/qtc_data_for_query/',
    params,
  );
};

export const getBoiFilingUserList = (params = {}) => {
  return axiosGet<{
    count: number;
    next: string | null;
    previous: string | null;
    results: BoiFilingData[];
  }>('/dashboard/tax_engine/boi_filing/get_boi_filing_data/', params);
};

export const updateBoiUserData = (
  boiId: number,
  body: {
    [BOI_FILING_COLUMNS.ASSIGNED_REVIEWER]?: VALID_CPA_EMAILS | null;
    [BOI_FILING_COLUMNS.STATUS]?: BOI_STATUS;
  },
) => {
  return axiosPatch(`/dashboard/tax_engine/boi_filing/${boiId}/`, {}, body);
};

export const getBoiBusinessDetails = (params: {
  fly_user_id: number;
  year: number;
}) => {
  return axiosGet<BoiBusinessForm>('/dashboard/boi_business_details/', params);
};

export const exportBoiColumns = () => {
  return axiosPost(
    '/dashboard/tax_engine/boi_filing/export_boi_filing_data/',
    {},
  );
};

export const getUnsubscribeEmailCategories = (fly_user_id: number) => {
  return axiosGet('/dashboard/users/get_unsubscribed_email_categories/', {
    fly_user_id,
  });
};

export const postUnsubscribeEmail = (fly_user_id: number) => {
  return axiosPost('/dashboard/users/unsubscribe_from_emails/', {
    fly_user_id,
  });
};

import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import Box from 'src/DesignSystem/Box/Box';
import Steps from 'src/DesignSystem/Steps/Steps';
import BackIcon from 'src/icons/BackIcon';
import useCpaCenterV2Navigation from '../hooks/useCpaCenterV2Navigation';
import useCurrentTaxReturnId from '../hooks/useCurrentTaxReturnId';
import useTaxReturnCurrentStep from '../hooks/useTaxReturnCurrentStep';
import useTaxReturnStep from '../hooks/useTaxReturnStep';
import AddReturn from './AddReturn/AddReturn';
import CpaReview from './CpaReview/CpaReview';
import ReviewAndEsign from './ReviewAndEsign/ReviewAndEsign';
import ReviewSummary from './ReviewSummary/ReviewSummary';
import Skeleton from 'src/icons/Skeleton';
import {getDocument} from 'src/appApi';
import TaxReturnSectionHeader from './common/TaxReturnSectionHeader';

const RenderComponent = ({taxReturnSection, documentUri}) => {
  switch (taxReturnSection) {
    case TaxReturnStatus.ADD_RETURN:
      return <AddReturn />;
    case TaxReturnStatus.REVIEW_SUMMARY:
      return <ReviewSummary url={documentUri} />;
    case TaxReturnStatus.CPA_REVIEW:
      return <CpaReview url={documentUri} />;
    case TaxReturnStatus.REVIEW_ESIGN:
      return <ReviewAndEsign url={documentUri} />;
  }
  return null;
};

const TaxReturnDetailedView = () => {
  const {returnId, currentReturn, taxReturnsLoaded} = useCurrentTaxReturnId();
  const {taxReturnSection} = useTaxReturnCurrentStep();
  const {taxReturnSteps} = useTaxReturnStep(returnId);
  const {navigateToTaxReturnDetailedView, navigateToReviewEsign} =
    useCpaCenterV2Navigation();
  const [documentUri, setDocumentUri] = useState(null);

  useEffect(() => {
    const refreshDocumentUri = async () => {
      if (!currentReturn?.document_url) {
        return;
      }
      const {data} = await getDocument(currentReturn.document_url);
      setDocumentUri(data);
    };
    refreshDocumentUri();
  }, [currentReturn?.document_url]);

  if (!taxReturnsLoaded) {
    return (
      <>
        <Skeleton width={'99%'} height={60} style={{marginTop: 12}} />
        <Skeleton width={'99%'} height={500} />
      </>
    );
  }

  return (
    <div style={{paddingTop: 12, paddingRight: 20}}>
      <Box
        style={{
          paddingBlock: 14,
          paddingInline: 20,
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 12,
        }}>
        <div style={{display: 'flex'}}>
          <BackIcon onClick={navigateToReviewEsign} />
          <Typography style={{fontWeight: 600, marginLeft: 8}}>
            {currentReturn.return_type === TaxReturnType.INDIVIDUAL
              ? 'Individual tax return'
              : 'Business tax return'}
          </Typography>
        </div>
        <Steps
          data={taxReturnSteps}
          onClick={(item) =>
            navigateToTaxReturnDetailedView(returnId, item.props.step)
          }
        />
      </Box>
      <TaxReturnSectionHeader />
      <RenderComponent
        taxReturnSection={taxReturnSection}
        documentUri={documentUri}
      />
    </div>
  );
};

export default TaxReturnDetailedView;

import {useNavigate} from 'react-router-dom';
import {TaxProfileSection} from 'src/constants/constants';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';
import {
  CpaCenterV2Route,
  CpaCenterV2RouteParts,
} from 'src/constants/routeName';
import useCurrentUserId from './useCurrentUserId';

const useCpaCenterV2Navigation = () => {
  const navigate = useNavigate();
  const {userId} = useCurrentUserId();

  const navigateToDocumentReview = () => {
    const to = CpaCenterV2Route.CpaCenterV2DocumentReview.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToDocumentViewer = (docId: number) => {
    const to = CpaCenterV2Route.CpaCenterV2DocumentViewer.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    ).replace(CpaCenterV2RouteParts.DocumentId, `${docId}`);
    navigate(to);
  };

  const navigateToTaxProfileReview = () => {
    const to = CpaCenterV2Route.CpaCenterV2TaxProfileReview.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToTaxProfileSection = (section: TaxProfileSection) => {
    const to =
      CpaCenterV2Route.CpaCenterV2TaxProfileReview.replace(
        CpaCenterV2RouteParts.UserId,
        `${userId}`,
      ) + `/${section}`;
    navigate(to);
  };

  const navigateToUserQueries = () => {
    const to = CpaCenterV2Route.CpaCenterV2UserQueries.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToMessagesView = (queryId: number) => {
    const to = CpaCenterV2Route.CpaCenterV2UserQueriesMessageView.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    ).replace(CpaCenterV2RouteParts.QueryId, `${queryId}`);
    navigate(to);
  };

  const navigateToReviewEsign = () => {
    const to = CpaCenterV2Route.CpaCenterV2ReviewEsign.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  const navigateToTaxReturnDetailedView = (
    returnId: number,
    step: TaxReturnStatus,
  ) => {
    const to = CpaCenterV2Route.CpaCenterV2ReviewEsignReturnStep.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    )
      .replace(CpaCenterV2RouteParts.ReturnId, `${returnId}`)
      .replace(CpaCenterV2RouteParts.TaxReturnSection, step);
    navigate(to);
  };

  const navigateToReviewEsignQuery = (returnId: number, queryId: number) => {
    const to = CpaCenterV2Route.CpaCenterV2ReviewEsignQuery.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    )
      .replace(CpaCenterV2RouteParts.ReturnId, `${returnId}`)
      .replace(
        CpaCenterV2RouteParts.TaxReturnSection,
        TaxReturnStatus.REVIEW_ESIGN,
      )
      .replace(CpaCenterV2RouteParts.QueryId, `${queryId}`);
    navigate(to);
  };

  const navigateToBrowserBack = () => {
    navigate(-1);
  };

  const navigateToDistributeDeductions = () => {
    const to = CpaCenterV2Route.CpaCenterV2DistributeDeductions.replace(
      CpaCenterV2RouteParts.UserId,
      `${userId}`,
    );
    navigate(to);
  };

  return {
    navigateToDocumentReview,
    navigateToDocumentViewer,
    navigateToTaxProfileReview,
    navigateToTaxProfileSection,
    navigateToUserQueries,
    navigateToMessagesView,
    navigateToTaxReturnDetailedView,
    navigateToReviewEsign,
    navigateToReviewEsignQuery,
    navigateToBrowserBack,
    navigateToDistributeDeductions,
  };
};

export default useCpaCenterV2Navigation;

import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  fromUnixTime,
  parseISO,
} from 'date-fns';
import {ROUTES_PLACEHOLDERS} from '../constants/constants';
import {AxiosResponse} from 'axios';

export const getValidatedAmount = (amount: number) => {
  // EPSILON is added because sometimes numbers like 1.875 are represented internally as
  // 1.874999 and rounding it to 2 decimal places would give 1.87. But here we want it to
  // be 1.88
  // See https://stackoverflow.com/q/11832914 for more discussion on how to do rounding
  return Math.round((amount + Number.EPSILON) * 100) / 100;
};

/**
 * Returns
 * e.g. 3 minutes ago, about 2 hours ago
 */
export const getDateDifference = (unixTimeStamp: number) => {
  return formatDistance(fromUnixTime(unixTimeStamp), new Date(), {
    addSuffix: true,
  });
};

/**
 * @returns today at 2:33 PM
 */
export const getRelativeDate = (unixTimeStamp: number) => {
  return formatRelative(fromUnixTime(unixTimeStamp), new Date());
};

export const injectFlyfinUserId = (
  link: string,
  flyfin_user_id?: string | number,
) => {
  return link.replace(
    ROUTES_PLACEHOLDERS.FLYFIN_USER_ID_PLACEHOLDER,
    `${flyfin_user_id}`,
  );
};

export const getFormattedDateForCSVImport = (inputDate: string) => {
  const inputDateFormat = 'MM/DD/YYYY';
  const outputDateFormat = 'DD-MM-YYYY';
  const [month, date, year] = inputDate.split('/');
  return [date, month, year].join('-');
};

export const colorFader = (color: string, enabled: boolean) => {
  return enabled ? `${color}55` : color;
};

// TODO: move to env file. Don't add environment speific variables here.
export const getEnvBasedConfig = () => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    return {
      REACT_APP_DATA_PROVIDER_URL: 'https://api-stg.flyfin.org',
      REACT_APP_GOOGLE_SHEETS_PRIVATE_KEY: `-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCOlAQqCpg9f6XS\nuNgEU8PqesOSa44bHryqkNBTbwMs/kwbK5j5V8RCMJUumR6c59keoKGdIuiVBgIN\n2g6p9hAtdLI87rqaYGBY/wJk4lXt+OOqqWCYWvVmIh7GsBsKSrMOvSu3x3IX2z8g\n6W2+X/tclwZJWpiKZ/8KLbp0kKVZWW3AZrR21c3f+oQKKeZnS5ZPrUfa8Xe/SpZi\ntUS4s7GXs6+LWxW1l2dF9WVvE5nEoDYxT73v4nDHeFKeDcruibhZRXtrtmRdhwkx\nvqpEzQ19sUqQn/74YjG0/BtS3/mkBhZ7RUxdKjh25DtyAuZoA/iUNkOBt5DU3dD1\nq8wrCgS9AgMBAAECggEAA/eTXBYaYU+tbf0NqaUpBNuwmGDcvd1vk3FfEos6s+RJ\netIOOhXalJrkc7z38Ja04FjI1mXzwb2+jeMc2FbL4W/nS7G6yHPZCnDiAKuZh3sO\n3p9C3EpfnJ1jbNTWk8U7UTQrRLxMbOSBT6mfD5Z9GHjtSFo4d1Vob8CQ+RBTQXak\nZarllSqHFUrhKTqKIvsjwbsI0DozCZwM4l1cDZteZn0wmrmKQSLlrY723U1JA+yt\nCOIjgPh2DM6D//KDOW6aTfpvaGSZTiECiIqjaq7KCsJUJXx6qlY2aoGI9ycZjdPL\nXUwYE4+4rUZXFCkxrDygFIw4X5ZMTVc8EJTJBWEH8wKBgQDImzeU1XM89HoXyeU0\nrJ365KfYBNvVTrgEpoHOpVZCFdZ3PO1jXvZ4hqtWiT0itbnRDniBSJgoc6rmPjRP\nnA2C4O5jjG7RdaCZiBvXFHpueC67K6Erm73pkrHs1t1W01mhBryWSyUNyg6+oSmD\n81kW84LMwT0NZk1DZ1/MLoOkuwKBgQC18tAvalY/PRK3EDodH8o2kQafvhN90y8I\nuiGSuENkb73CdiNu/zcI76Ip0LAqGGtrcf77o0tDKmDb/Tlp6/R9RJK0XDmlwZmM\n2z1UTUjDKj1uf8rKQy2eBYROiXp/3lAOQZQZuZpv9TPHkrBGG1aIdMmEHaxMB1+S\nAQQbMusg5wKBgAnBWpOXSbJWJTXQ8J9+IXSY9/yOR+54lIRNYJSe/7HZBAPqZ5Tk\nglHcIqrjo6Bze+TJslx/Hj8hWNc3gS255gJOU+ltY9o1wSiWMNxlt4p1t7gMaBsJ\nyNN5+cMNs9fMEVi+Th906glgoRTuG2m6RMPT9bFlWEW1it5TQZpZTLePAoGAIV41\nheyXgqN9sir8rWiuGhHAyD0AsBuxzYTtMU4vtdS0VVx7JRkTC8V+1PJbzGyMDrlq\nTNXmsJuuBcpL5DwMq4iO6xray6ZUtbtjZPmDdZ9igshW9X8ad5eFay3VVGv9/I9U\nTN94Dj5Gz7v8Le9HkGDysBMNDShxA4LCMQbGjlMCgYApSxgC8WzTYJmqu3xJ905w\nv16OOGd6rsbpoBLEcthX/pDT55/GaY9cqHFdJjDLcww3ue4f4/Nk8CCiDhX3r0FH\nFLXG/mdQ38xGX+ozj/3Cm4VrZ8ERD1xzGPlWxVa3GGeZAQyANIEersqyu954LNSZ\ncZ7hkPTODNmNJES9aHComg==\n-----END PRIVATE KEY-----\n`,
      REACT_APP_GOOGLE_SHEETS_CLIENT_EMAIL: `stg-marketing-website@appspot.gserviceaccount.com`,
      REACT_APP_SPREADSHEET_ID: `1RLR3QL48Qd4DGaPyyz8nLFjElEX67z1Eh9oxPQClY0k`,
      REACT_APP_SHEET_ID: 2092002569,
    };
  }
  return {
    REACT_APP_DATA_PROVIDER_URL: 'https://api.flyfin.org',
    REACT_APP_GOOGLE_SHEETS_PRIVATE_KEY: `-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQC98AiQ+Vf1b/h+\nmS5i72U346lv2j/rfFsAqZCOZ+bnk+JED3e6DB7XU6fRQw/GP9OYLYslMx+RHuJi\nmhudQwWxKfp8jYNkH6pPgmzwMvZYG95TCXYu/DBh+K9J2psQvMymQCqHhG3YNuFj\nKNcngLAUV9uqn0OD8VYdJZ8d3GakUb9ADtc1C0LWfMdOei8L1Y1luA/rneH95+31\n1d/ur62SqzfCBL6ISViyLxF2iaU5Fz4uZCTJjvE8jfbLLcqHzqXNJ68NT37l0Dht\nnGYRIK+SCZmn71KPizlvVPO01EXH558BXQ3ELyZ5EX0O/dmmijQ01ztHPTX7pR0O\nFN+HtWxVAgMBAAECggEADiFKydo64G/1iRmFOWf83DUMGF2grrigHoL+1ZFGPMkk\nJpMOAaHAM52uGOPHyyRZbfNJbDmK6iVlixdS4ZaoWD86usReV3cBmQunZhEsgW6F\nUiEckS9CvC8ffzuLmKmrAd05LL692z1t9A4IP5CTyNemaGGTyL+N+/oeBb8tRaEM\nWlAawywzYCr7n/rUDfx/KRW7vrOlu1bsbxwWSOga3swhiCtEC3F4p993xMn0fRgH\nwpdgYI7l2X1LYnOBbozkATY5UssRPMM7AxcH5GFXS63R+QDcFOX6vgU6YZwjibRd\ndfHDw1SAvATwRiLdL+R8gimNYyWKo2F0eX3pjshviwKBgQDr/fTV/ubj34Q/GDyx\nU2zpgBmo1P57pa9EZEBoDnKqPY78j16X04ARI47YE3pkphuHxYNkwPR2AW+0mKTn\nYe69LFFZ6r42y988LNc+DBqcf98kXcS/FGrZNmF9FLzOM51m5T6bs3tq1iqYyeNh\nlBhojwIdcSqjoJul2mUHyLUgYwKBgQDOCn+aSEkLfBxhGIYAhq53p7fTQdDQEatg\nc0k07eVjyzMN/9vULgZrUqfouNhQnNPQhpcIBlTIWBGIOrtM8o7VlsUicC3iKsWM\nG0cPh5I73kGUaN2fOqTlskVhUNtdVXGQmEmEW+5oGIkmh8fU5EgH8dwtGv4Gvg+k\nkjTJ+pjx5wKBgQDpPpmsoEGIW7STv9rM0M3Gg0vZKJtVjk6KZCOcNRtz01/tk65E\nlU/woJpgSfAgZmSzbq4M+QElWQoVDwF9h7e01YaaPn8+HYZILXGX69Kpd2EOynbY\n/M8WXL9CJyrtCuq5dj7JGT4UPBTh6jiIix0MyrumaeFg1C7CX/aXGPKcTQKBgQCF\nSP9Qs7Ak8M1+9hlBX96e4u0kiS/mWXfOdeWGitpYWwuU4822DWvzo02hW2n7g+f1\nvgsXktjnnSkCQlQFq+dAPj8aTw0vgFG4l7C5WA7+EYHE2q17IrmnKVJ+aIrvbqV/\nTzRHnhy0VCLsvOtFScNa+FdhClQH0o1V/YqOmkC0QwKBgQDqg4QPgVdXuCKJoPdR\nnUH20+reMzN3JY24ud/d+ECdEQhdT3f9TAxUVEmJ26dl3NzItv6yhKYVi9nhCFpo\niE6WBpw1vpy7kT+7HeKLdmtmF0tTai0yVxFLHnrJquC47Jx6DptNieUr+hxvXjee\nEUgboPJQwoxb0TOfbfySPY2JCw==\n-----END PRIVATE KEY-----\n`,
    REACT_APP_GOOGLE_SHEETS_CLIENT_EMAIL: `prod-google-sheet@prod-marketing-website.iam.gserviceaccount.com`,
    REACT_APP_SPREADSHEET_ID: `17sVduOaipQYZzCsW2KZ9NWtDq-GcWLJgtAHzCtIIy28`,
    REACT_APP_SHEET_ID: 1046949332,
  };
};

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// poll on `callback` function after every `ms` milliseconds and after every response from callback function for `triesLeft` times and stops if `successCondition` is true
export const pollRequest = async (
  callback,
  successCondition,
  ms = 1000, // 1 sec
  triesLeft = 40,
) => {
  return new Promise(async (resolve, reject) => {
    for (let i = 0; i < triesLeft; i++) {
      try {
        const response = await callback();
        if (successCondition(response)) {
          resolve(response);
          break;
        }
        await sleep(ms);
      } catch (e) {
        reject(`Get ocr data api failed - ${e.message}`);
        break;
      }
    }
    reject('No tries left for polling');
  });
};

export const handleAsyncRequest = <T extends Promise<AxiosResponse<any>>>(
  promise: T,
) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => [undefined, error]);
};

export const getFormattedDateTime = (
  startUTC: string | null,
  endUTC: string | null,
) => {
  if (!startUTC || !endUTC) return '';
  return `${format(parseISO(startUTC), 'MMM dd, hh:mm aaa')} - ${format(
    parseISO(endUTC),
    'hh:mm aaa',
  )}`;
};

export function CurrencyNumberFormat(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export const getAge = (dob: Date) => {
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export function getDateFromYYYYMMDD(dateStr) {
  // IMPORTANT NOTE: Use this function instead of directly constructing
  // JS Date from string expecially if you are using this date as an arg to
  // date-fns function
  return new Date(parseISO(dateStr));
}

export const validateSSN = (ssn) => {
  /**
   * reference: https://uibakery.io/regex-library/ssn
   */
  return /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(ssn);
};

export function validateEmail(email) {
  // remove leading and trailing spaces before passing the argument to this function
  // took regex from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript#comment15817351_9204568
  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email || reg.test(email) === false) {
    return false;
  } else {
    return true;
  }
}

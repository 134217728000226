import {
  BusinessDetails,
  BusinessType,
  BUSINESS_DETAILS_INPUT_TYPE,
  FinalFieldType,
  ShareHolder,
} from './businessDetails.reducer';

const getBusinessAddressFields = (business: BusinessDetails) => {
  return [
    {
      name: 'House number and street',
      value: business.address?.numberAndStreet,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Apartment/Unit number (if applicable)',
      value: business.address?.apartmentNumber,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'City',
      value: business.address?.city,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'State',
      value: business.address?.state,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Zip code',
      value: business.address?.zipCode,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Same as home address',
      value: business.isSameAsHomeAddress,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getShareholderAddressFields = (shareholder: ShareHolder) => {
  return [
    {
      name: 'House number and street',
      value: shareholder.address.numberAndStreet,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Apartment/Unit number (if applicable)',
      value: shareholder.address.apartmentNumber,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'City',
      value: shareholder.address.city,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'State',
      value: shareholder.address.state,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Zip code',
      value: shareholder.address.zipCode,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getCommonSoleProprietorshipFields = (business: BusinessDetails) => {
  return [
    {
      name: 'Type of business',
      value: business.entityType,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: business.name,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Owner or proprietor',
      value: business.ownerOrProprietor,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: business.professions,
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Start date',
      value: business.startDate,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getSoleProprietorshipFieldsIfEINPresent = (
  business: BusinessDetails,
) => {
  return [
    {
      name: 'EIN',
      value: business.ein,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: business.isRegisteredWithState,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

const getSoleProprietorshipFieldsIfEINNotPresent = (
  business: BusinessDetails,
) => {
  return [
    {
      name: 'This work is still continuing?',
      value: business.is_work_continuing,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'When did you stop this work?',
      value: business.work_stop_date,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: ' Are you and your spouse co-owners in this business / freelance activity?',
      value: business.is_spouse_business_partner,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
  ];
};

const getFieldsForShareHolder = (shareholder: ShareHolder) => {
  return [
    {
      name: 'First name & middle initial',
      value: shareholder.firstName,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Last name',
      value: shareholder.lastName,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Social Security Number',
      value: shareholder.ssn,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Ownership %',
      value: shareholder.ownershipPct,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Another individual acts on behalf of this shareholder',
      value: shareholder.isAnotherIndividualActOnBehalf,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholder Address',
      value: getShareholderAddressFields(shareholder),
      type: BUSINESS_DETAILS_INPUT_TYPE.SHAREHOLDER_ADDRESS,
    },
  ];
};

const getShareholdersDetails = (shareholders: ShareHolder[]) => {
  return shareholders.map((shareholder, index) => ({
    name: `Shareholder ${index + 1}`,
    value: getFieldsForShareHolder(shareholder),
    type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_SHAREHOLDER,
  }));
};

const getDocumentsInformation = (business: BusinessDetails) => {
  return business.documentLinks?.map((docInfo) => ({
    name: docInfo.filename,
    type: BUSINESS_DETAILS_INPUT_TYPE.SINGLE_DOCUMENT,
    value: docInfo.docId,
  }));
};

const getFieldsForPartnershipOrCCorp = (business: BusinessDetails) => {
  return [
    {
      name: 'Type of business',
      value: business.entityType,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: business.name,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: business.professions,
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Incorporation date',
      value: business.incorporationDate,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Phone number',
      value: business.phoneNumber,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'EIN',
      value: business.ein,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Accounting method',
      value: business.accountingMethod,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: business.isRegisteredWithState,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Signing authority id',
      value: getSigningAuthorityShareHolderName(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholders address',
      value: getShareholdersDetails(business.shareHolders ?? []),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

const getSigningAuthorityShareHolderName = (business: BusinessDetails) => {
  const signingAuthorityShareholder = business.shareHolders?.find(
    (shareholder) => shareholder.id === business.signingAuthorityId,
  );
  return `${signingAuthorityShareholder?.firstName ?? ''} ${
    signingAuthorityShareholder?.lastName ?? ''
  }`;
};

const getFieldsForScorp = (business: BusinessDetails) => {
  return [
    {
      name: 'Type of business',
      value: business.entityType,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Business name',
      value: business.name,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Professions',
      value: business.professions,
      type: BUSINESS_DETAILS_INPUT_TYPE.ARRAY,
    },
    {
      name: 'Incorporation date',
      value: business.incorporationDate,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Scorp effective date',
      value: business.sCorpEffectiveDate,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Phone number',
      value: business.phoneNumber,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'EIN',
      value: business.ein,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Accounting method',
      value: business.accountingMethod,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Is registered with state?',
      value: business.isRegisteredWithState,
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Registered business address',
      value: getBusinessAddressFields(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.BUSINESS_ADDRESS,
    },
    {
      name: 'Signing authority id',
      value: getSigningAuthorityShareHolderName(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.DEFAULT,
    },
    {
      name: 'Shareholders address',
      value: getShareholdersDetails(business.shareHolders),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_SHAREHOLDERS,
    },
    {
      name: 'Related Documents',
      value: getDocumentsInformation(business),
      type: BUSINESS_DETAILS_INPUT_TYPE.MULTIPLE_DOCUMENTS,
    },
  ];
};

export const getCorrectFieldsForBusiness: (
  business: BusinessDetails,
) => FinalFieldType[] = (business: BusinessDetails) => {
  if (business.entityType === BusinessType.SOLE_PROPRIETORSHIP) {
    return [
      ...getCommonSoleProprietorshipFields(business),
      ...(business.haveEin
        ? getSoleProprietorshipFieldsIfEINPresent(business)
        : getSoleProprietorshipFieldsIfEINNotPresent(business)),
    ];
  }
  if (
    [BusinessType.PARTNERSHIP, BusinessType.C_CORP].includes(
      business.entityType,
    )
  ) {
    return [...getFieldsForPartnershipOrCCorp(business)];
  }
  if ([BusinessType.S_CORP].includes(business.entityType)) {
    return [...getFieldsForScorp(business)];
  }

  return [] as FinalFieldType[];
};

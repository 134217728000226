import {SET_APP_STATE} from '../actionNames';
import {
  AppReducerStates,
  AppReducerType,
  NotificationType,
} from './app.reducer';

export const setAppState = (path: AppReducerStates, value: any) => {
  // Uncomment below for stronger error
  // if (
  //   path === AppReducerStates.notifcationStatus &&
  //   value?.type === NotificationType.error &&
  //   value?.isVisible
  // ) {
  //   alert(value?.message);
  // }
  return {
    type: SET_APP_STATE,
    payload: {
      path,
      value,
    },
  };
};

export const setHighValueDeductionsDrawer = (isVisible: boolean) => {
  return (dispatch: any) => {
    dispatch(
      setAppState(AppReducerStates.highValueDeductionsDrawer, isVisible),
    );
  };
};

export const setReuploadDocumentQueryDrawer = (
  payload: AppReducerType[AppReducerStates.reuploadDocumentQueryDrawer],
) => {
  return setAppState(AppReducerStates.reuploadDocumentQueryDrawer, payload);
};

export const setFileExtensionDetailsDrawer = (isVisible: boolean) => {
  return setAppState(AppReducerStates.fileExtensionDetailsDrawer, isVisible);
};

import {
  TaxProfileSection,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';

export const getTaxProfileSectionFromSubsection = (
  subsection: TaxProfileSubsection,
) => {
  const sections = Object.keys(TaxProfileSectionToSubsectionMapping);
  let targetSection = 'batman';
  sections.forEach((section: TaxProfileSection) => {
    if (TaxProfileSectionToSubsectionMapping[section].includes(subsection)) {
      targetSection = section;
    }
  });
  return targetSection as TaxProfileSection;
};

export const getFieldValue = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return 'NA';
  }
  return value;
};

import {themmeColor, VALID_CPA_EMAILS} from 'src/constants/constants';
import {addDays, format, parse, subDays} from 'date-fns';
import {
  CpaCenterV2Route,
  CpaCenterV2RouteParts,
} from 'src/constants/routeName';
import {USER_COMMS_MODE} from 'src/store/user/user.reducer';
import {TAX_FILING_STATUS} from 'src/constants/constants';

export enum CPA_LIST_COLUMNS {
  USER_ID = 'User ID',
  NAME = 'Name',
  EMAIL_ADDRESS = 'Email address',
  LAST_STATUS_CHANGE = 'Last status change',
  DOCS_SUBMITTED = 'Docs Submitted',
  RETURN_STATUS = 'Return status',
  CPA_ACTION = 'Cpa Action',
  REVIEWER_ACTION = 'Reviewer Action',
  EXTENSION_STATUS = 'Extension status',
  NEXT_ACTION_DATE = 'Next action date',
  CPA = 'CPA',
  PLANS = 'Plan',
  NOTE = 'Notes',
  FILING_STATUS = 'Filing status',
  BUSINESS_TYPE = 'Business type',
  ACTIVITY = 'Activity',
  TAX_QUERY_OPEN = 'Tax query open',
  CCH_CLIENT_ID = 'CCH Client ID',
  REVIEWER = 'Reviewer',
  COMMS_MODE = 'Comms mode',
  ESIGN_MODE = 'Esign mode',
  OLDEST_OPEN_QUERY_DATE = 'Oldest open query',
  OPEN_SEND_QUERT_STATS = 'Need action from CPA',
  COLLABORATOR_EMAIL = 'Collaborator',
  FILED_ELSEWHERE = 'Filed Taxes Elsewhere',
}

export enum BACKEND_RESPONSE_KEYS {
  FLYFIN_USER_ID = 'fly_user_id',
  FLYFIN_USER_NAME = 'fly_user_name',
  FLYFIN_USER_EMAIL = 'fly_user_email',
  LAST_STATUS_CHANGE = 'last_status_change_date',
  DOCS_SUBMITTED = 'docs_submitted_date',
  STATUS = 'cpa_facing_ret_status',
  CPA_ACTION = 'is_cpa_action_required',
  REVIEWER_ACTION = 'is_reviewer_action_required',
  EXTENSION_STATUS = 'tax_extension_status',
  NEXT_ACTION_DATE = 'next_action_date',
  CPA = 'assigned_cpa',
  PAYMENT_PLAN = 'payment_plan',
  NOTE = 'notes',
  TAX_FILING_ID = 'tax_filing_id',
  FILING_STATUS = 'filing_status',
  BUSINESS_TYPE = 'business_type',
  ACTIVITY = 'activeness_status',
  TAX_QUERY_OPEN = 'is_open_tax_query',
  CCH_CLIENT_ID = 'cch_client_id',
  CPA_REVIEWER = 'assigned_reviewer',
  CPA_FACING_RETURN_STATUS = 'cpa_facing_ret_status',
  COMMS_MODE = 'comms_mode',
  OLDEST_OPEN_QUERY_DATE = 'oldest_open_query_date',
  OPEN_SEND_QUERT_STATS = 'open_send_query_stats',
  ESIGN_MODE = 'esign_mode',
  COLLABORATOR_EMAIL = 'collaborator_email',
  EXPRESS_EXTENSION_ELIGIBLE = 'express_extension_eligible',
  TF_SEGMENT = 'tf_segment',
  FILED_ELSEWHERE = 'filed_elsewhere',
}

export interface Record {
  [BACKEND_RESPONSE_KEYS.FLYFIN_USER_ID]: number;
  [BACKEND_RESPONSE_KEYS.FLYFIN_USER_NAME]: string;
  [BACKEND_RESPONSE_KEYS.FLYFIN_USER_EMAIL]: string;
  [BACKEND_RESPONSE_KEYS.LAST_STATUS_CHANGE]: string;
  [BACKEND_RESPONSE_KEYS.DOCS_SUBMITTED]: string;
  [BACKEND_RESPONSE_KEYS.STATUS]: number;
  [BACKEND_RESPONSE_KEYS.CPA]: VALID_CPA_EMAILS;
  [BACKEND_RESPONSE_KEYS.CPA_ACTION]: number;
  [BACKEND_RESPONSE_KEYS.REVIEWER_ACTION]: number;
  [BACKEND_RESPONSE_KEYS.EXTENSION_STATUS]: EXTENSION_STATUS;
  [BACKEND_RESPONSE_KEYS.NEXT_ACTION_DATE]: string;
  [BACKEND_RESPONSE_KEYS.PAYMENT_PLAN]: string;
  [BACKEND_RESPONSE_KEYS.NOTE]: string;
  [BACKEND_RESPONSE_KEYS.TAX_FILING_ID]: number;
  [BACKEND_RESPONSE_KEYS.FILING_STATUS]: string;
  [BACKEND_RESPONSE_KEYS.BUSINESS_TYPE]: string;
  [BACKEND_RESPONSE_KEYS.ACTIVITY]: string;
  [BACKEND_RESPONSE_KEYS.TAX_QUERY_OPEN]: boolean;
  [BACKEND_RESPONSE_KEYS.CPA_REVIEWER]: VALID_CPA_EMAILS;
  [BACKEND_RESPONSE_KEYS.COMMS_MODE]: USER_COMMS_MODE;
  [BACKEND_RESPONSE_KEYS.OLDEST_OPEN_QUERY_DATE]: string;
  [BACKEND_RESPONSE_KEYS.OPEN_SEND_QUERT_STATS]: string;
  [BACKEND_RESPONSE_KEYS.ESIGN_MODE]: string;
  [BACKEND_RESPONSE_KEYS.COLLABORATOR_EMAIL]: VALID_CPA_EMAILS;
  [BACKEND_RESPONSE_KEYS.FILED_ELSEWHERE]: boolean;
}

export enum USER_ACTIVITY_TYPE {
  ACTIVE = 'ACTIVE',
  DORMANT = 'DORMANT',
}

export const PAYMENT_PLAN = {
  BASIC: 'FlyFin Basic',
  STANDARD: 'FlyFin Standard',
  PREMIUM: 'FlyFin Premium',
  EXCLUSIVE: 'FlyFin Exclusive',
  ULTIMATE: 'FlyFin Ultimate',
  LIMITED: 'FlyFin Limited',
};

export enum EXTENSION_STATUS {
  NULL = 'NULL',
  REQUEST = 'REQUEST',
  // AWAITING_DETAILS = 'AWAITING_DETAILS',
  FILED = 'FILED',
}

export const TAX_FILING_SEQUENCE = [
  TAX_FILING_STATUS.NOT_INITIATED,
  TAX_FILING_STATUS.TAX_PROFILE_SUBMITTED,
  TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
  TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
  TAX_FILING_STATUS.USER_QUERIES_SENT,
  TAX_FILING_STATUS.PREP_STARTED,
  TAX_FILING_STATUS.SUMMARY_REVIEWED,
  TAX_FILING_STATUS.RETURN_SENT_TO_USER,
  TAX_FILING_STATUS.USER_ESIGNED,
  TAX_FILING_STATUS.BANK_DETAILS_SUBMITTED,
  TAX_FILING_STATUS.EFILED,
  TAX_FILING_STATUS.REJECTED_FROM_IRS,
  TAX_FILING_STATUS.APPROVED_FROM_IRS,
];

export const CPA_CENTER_V2_VALID_STEPS_SEQUENCE = [
  TAX_FILING_STATUS.NOT_INITIATED,
  TAX_FILING_STATUS.TAX_PROFILE_SUBMITTED,
  TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
  TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
  TAX_FILING_STATUS.USER_QUERIES_SENT,
  TAX_FILING_STATUS.PREP_STARTED,
  TAX_FILING_STATUS.DETAILS_SENT_TO_CCH,
  TAX_FILING_STATUS.RETURN_ADDED,
  TAX_FILING_STATUS.SUMMARY_REVIEWED,
  TAX_FILING_STATUS.RETURN_SENT_TO_USER,
  TAX_FILING_STATUS.USER_ESIGNED,
  TAX_FILING_STATUS.BANK_DETAILS_SUBMITTED,
  TAX_FILING_STATUS.EFILED,
  TAX_FILING_STATUS.REJECTED_FROM_IRS,
  TAX_FILING_STATUS.APPROVED_FROM_IRS,
];

export const isCpaCenterV2StepComplete = (
  currentStep: TAX_FILING_STATUS,
  targetStep: TAX_FILING_STATUS,
) => {
  const currentStepIndex = CPA_CENTER_V2_VALID_STEPS_SEQUENCE.findIndex(
    (s) => s === currentStep,
  );
  const targetStepIndex = CPA_CENTER_V2_VALID_STEPS_SEQUENCE.findIndex(
    (s) => s === targetStep,
  );
  return targetStepIndex <= currentStepIndex;
};

export const getCpaCenterV2Step = (status: TAX_FILING_STATUS) => {
  const isTaxProfileReviewComplete = isCpaCenterV2StepComplete(
    status,
    TAX_FILING_STATUS.USER_QUERIES_SENT,
  );
  const isDocumentReviewComplete = isCpaCenterV2StepComplete(
    status,
    TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
  );
  if (isTaxProfileReviewComplete) {
    return CpaCenterV2RouteParts.UserQueries;
  }
  if (isDocumentReviewComplete) {
    return CpaCenterV2RouteParts.TaxProfileReview;
  }
  return CpaCenterV2RouteParts.DocumentReview;
};

export const isTaxFilingStatusComplete = (
  currTaxFilingStatus: TAX_FILING_STATUS,
  taxFilingStatus: TAX_FILING_STATUS,
) => {
  const currTaxFilingStatusIndex = TAX_FILING_SEQUENCE.findIndex(
    (status) => status === currTaxFilingStatus,
  );
  const taxFilingStatusIndex = TAX_FILING_SEQUENCE.findIndex(
    (status) => status === taxFilingStatus,
  );
  return currTaxFilingStatusIndex > taxFilingStatusIndex;
};

export const TAX_RETURN_STATUS_LABELS = {
  [TAX_FILING_STATUS.NOT_INITIATED]: {
    label: 'Not initiated',
    id: TAX_FILING_STATUS.NOT_INITIATED,
    backColor: themmeColor.backgroundOffWhite,
    textColor: themmeColor.black,
  },
  [TAX_FILING_STATUS.TAX_PROFILE_SUBMITTED]: {
    label: 'Tax profile Submitted',
    id: TAX_FILING_STATUS.TAX_PROFILE_SUBMITTED,
    backColor: themmeColor.backgroundOffWhite,
    textColor: themmeColor.black,
  },
  [TAX_FILING_STATUS.TAX_DOCS_REVIEWED]: {
    label: 'Docs approved',
    id: TAX_FILING_STATUS.TAX_DOCS_REVIEWED,
    backColor: themmeColor.translucentYellow,
    textColor: themmeColor.flyfinYellow,
  },
  [TAX_FILING_STATUS.TAX_PROFILE_REVIEWED]: {
    label: 'Tax Profile Reviewed',
    id: TAX_FILING_STATUS.TAX_PROFILE_REVIEWED,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
  [TAX_FILING_STATUS.USER_QUERIES_SENT]: {
    label: 'Sent User Queries',
    id: TAX_FILING_STATUS.USER_QUERIES_SENT,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
  [TAX_FILING_STATUS.PREP_STARTED]: {
    label: 'Prep started',
    id: TAX_FILING_STATUS.PREP_STARTED,
    backColor: themmeColor.translucentYellow,
    textColor: themmeColor.flyfinYellow,
  },
  [TAX_FILING_STATUS.DETAILS_SENT_TO_CCH]: {
    label: 'Details Sent to CCH',
    id: TAX_FILING_STATUS.DETAILS_SENT_TO_CCH,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
  [TAX_FILING_STATUS.RETURN_ADDED]: {
    label: 'Return uploaded',
    id: TAX_FILING_STATUS.RETURN_ADDED,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
  [TAX_FILING_STATUS.SUMMARY_REVIEWED]: {
    label: 'Return summary reviewed',
    id: TAX_FILING_STATUS.SUMMARY_REVIEWED,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
  [TAX_FILING_STATUS.RETURN_SENT_TO_USER]: {
    label: 'Return sent to user',
    id: TAX_FILING_STATUS.RETURN_SENT_TO_USER,
    backColor: themmeColor.translucentYellow,
    textColor: themmeColor.flyfinYellow,
  },
  [TAX_FILING_STATUS.USER_ESIGNED]: {
    label: 'User Esigned',
    id: TAX_FILING_STATUS.USER_ESIGNED,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
  [TAX_FILING_STATUS.BANK_DETAILS_SUBMITTED]: {
    label: 'Bank Details Submitted',
    id: TAX_FILING_STATUS.BANK_DETAILS_SUBMITTED,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
  [TAX_FILING_STATUS.EFILED]: {
    label: 'eFiled',
    id: TAX_FILING_STATUS.EFILED,
    backColor: themmeColor.translucentYellow,
    textColor: themmeColor.flyfinYellow,
  },
  [TAX_FILING_STATUS.REJECTED_FROM_IRS]: {
    label: 'Rejected from IRS',
    id: TAX_FILING_STATUS.REJECTED_FROM_IRS,
    backColor: themmeColor.translucentRed,
    textColor: themmeColor.errorRed,
  },
  [TAX_FILING_STATUS.APPROVED_FROM_IRS]: {
    label: 'Approved from IRS',
    id: TAX_FILING_STATUS.APPROVED_FROM_IRS,
    backColor: themmeColor.translucentGreen,
    textColor: themmeColor.successGreen,
  },
};

export const MANNUALY_NON_ASSIGNABLE_TAX_RETURN_STATUS_IDS = [
  TAX_FILING_STATUS.NOT_INITIATED,
  TAX_FILING_STATUS.BANK_DETAILS_SUBMITTED,
];

export enum ACTION_TYPES {
  NO = 'no',
  YES = 'yes',
}

export interface ValidDateTimeStamps {
  label: string;
  timestamp: string;
}

export const getMonthDateFromDate = (date: Date) => {
  try {
    return format(date, 'MMMM d');
  } catch {
    return '';
  }
};

export const getValuesForDateFilter: () => ValidDateTimeStamps[] = () => {
  const OFFSET = 7;
  const today = new Date(new Date().toDateString());
  const start = subDays(today, OFFSET);
  const values: Array<ValidDateTimeStamps> = [];
  for (var i = 0; i < OFFSET * 2 + 1; i++) {
    const current = addDays(start, i);
    values.push({
      label: getMonthDateFromDate(current),
      timestamp: getDateForNextAction(current),
    });
  }
  values.push({
    label: 'No action date',
    timestamp: 'unassigned',
  });
  return values;
};

export const getDateTimeFromMonthDayString = (date: string) => {
  try {
    return parse(date, 'yyyy-MM-dd', new Date());
  } catch {
    return '';
  }
};

export const getDateForNextAction = (date: Date) => {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch {
    return '';
  }
};

import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import {
  MessageAuthorType,
  ReduxMessageType,
} from 'src/store/queries/queries.reducer';
import MessageHeader from './MessageHeader';
import ReactHtmlParser from 'react-html-parser';
import DSButton from 'src/DesignSystem/Button/Button';
import SendIcon from 'src/icons/SendIcon';
import {useMessageList} from '../useMessageList';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import { useDispatch } from 'react-redux';
import { fetchQueries } from 'src/store/queries/queries.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';

interface DraftMessageProps {
  message: ReduxMessageType;
}

const DraftMessage = ({message}: DraftMessageProps) => {
  const isFromUser = message.authorType === MessageAuthorType.END_USER;
  const {
    draftEditMode,
    setDraftEditMode,
    setMessage,
    setIsLoading,
    isLoading,
  } = useMessageList();
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();

  const {sendDraftMessageToUser, deleteMessage} = useQueryAction();

  const onEditDraft = () => {
    setMessage(message.message);
    setDraftEditMode({
      isDraftEditMode: true,
      draftMessageId: message.messageId,
    });
  };

  const onSendDraftMessage = async () => {
    try {
      setIsLoading(true);
      await sendDraftMessageToUser({messageId: message.messageId});
      await dispatch(fetchQueries({userId}))
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteDraftMessage = async () => {
    try {
      setIsLoading(true);
      await deleteMessage({messageId: message.messageId});
      await dispatch(fetchQueries({userId}))
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        flex: 1,
        alignItems: isFromUser ? 'flex-start' : 'flex-end',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <MessageHeader message={message} />
      <Box
        style={{
          paddingBlock: 12,
          paddingInline: 16,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '35vw',
          backgroundColor: themmeColor.draftQueryBgColor,
          ...(isFromUser
            ? {borderBottomLeftRadius: 0}
            : {borderBottomRightRadius: 0}),
        }}>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 600,
            marginBottom: 12,
          }}>
          Saved as draft
        </Typography>
        <Typography
          style={{
            color: themmeColor.black,
            overflow: 'auto',
          }}>
          {ReactHtmlParser(message.message)}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 8,
          }}>
          <div style={{display: 'flex', marginRight: 16}}>
            <DSButton
              type={'secondary'}
              text="Edit"
              style={{marginRight: 8}}
              onClick={onEditDraft}
              disabled={draftEditMode.isDraftEditMode || isLoading}
            />
            <DSButton
              type={'secondary'}
              text="Delete"
              onClick={onDeleteDraftMessage}
              disabled={draftEditMode.isDraftEditMode || isLoading}
            />
          </div>
          <DSButton
            type="primary"
            onClick={onSendDraftMessage}
            disabled={draftEditMode.isDraftEditMode || isLoading}
            text="Send"
            endIcon={<SendIcon />}
          />
        </div>
      </Box>
    </div>
  );
};

export default DraftMessage;

import React from 'react';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import {MessageAuthorType} from 'src/store/queries/queries.reducer';
import ReactHtmlParser from 'react-html-parser';
import Box from 'src/DesignSystem/Box/Box';
import {MessageProps} from './Message';
import MessageHeader from './MessageHeader';

export const NormalMessage = ({message}: MessageProps) => {
  const isFromUser = message.authorType === MessageAuthorType.END_USER;

  return (
    <div
      style={{
        flex: 1,
        alignItems: isFromUser ? 'flex-start' : 'flex-end',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <MessageHeader message={message} />
      <Box
        style={{
          paddingBlock: 12,
          paddingInline: 16,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '35vw',
          backgroundColor: !isFromUser ? themmeColor.textPurple : '#F4F1F9',
          ...(isFromUser
            ? {borderBottomLeftRadius: 0}
            : {borderBottomRightRadius: 0}),
        }}>
        <Typography
          style={{
            color: isFromUser ? themmeColor.textPurple : themmeColor.white,
            overflow: 'auto',
          }}>
          {ReactHtmlParser(message.message)}
        </Typography>
      </Box>
    </div>
  );
};

import {SET_EFILE_LOGS} from '../actionNames';
import {REDUX_STATUS, ReduxActionType} from '../types';

interface EfileLog {
  title: string;
  date: string;
  warning: boolean;
}

type EfileLogs = {[taxReturnId: number]: EfileLog[]};

const initialState = {
  efileLogs: {} as EfileLogs,
  loaded: false,
  status: REDUX_STATUS.INIT,
};

export const efileLogs = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_EFILE_LOGS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
    }
  }
  return state;
};

export type EfileLogsStateType = typeof initialState;

import React from 'react';
import {useDispatch} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import {setAppState} from 'src/store/app/app.actions';
import {
  AppReducerStates,
  CreateQueryDrawerTab,
} from 'src/store/app/app.reducer';

const useCreateTaxProfileQuery = () => {
  const dispatch = useDispatch();

  const openCreateQueryDrawer = (section: TaxProfileSubsection) => {
    console.log({section})
    dispatch(
      setAppState(AppReducerStates.createQueryDrawerStatus, {
        isVisible: true,
        selectedTab: CreateQueryDrawerTab.TAX_PROFILE,
        selectedDropdown: section,
      }),
    );
  };

  return {
    openCreateQueryDrawer,
  };
};

export default useCreateTaxProfileQuery;

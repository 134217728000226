import {Typography} from '@mui/material';
import React from 'react';
import {themmeColor} from 'src/constants/constants';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import Box from 'src/DesignSystem/Box/Box';
import UploadReturn from './components/UploadReturn';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';

const AddReturn = () => {
  const {returnId} = useCurrentTaxReturnId();
  const {spaceLeftBelowTaxReturnSection} = useLayout();
  return (
    <div>
      <Box style={{padding: 20, height: spaceLeftBelowTaxReturnSection}}>
        <div
          style={{
            flex: 0.7,
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 20,
          }}>
          <UploadReturn />
        </div>
        <Box
          style={{
            flex: 0.3,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: themmeColor.cpaCenterV2Bg,
          }}>
          <Typography style={{color: themmeColor.grey, textAlign: 'center'}}>
            Upload document to
            <br />
            find the OCR data here
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default AddReturn;

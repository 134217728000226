import {ReduxStateType} from '../store';
import {ReduxMessageType, ReduxQueryType} from './queries.reducer';

export const selectQueriesReducer = (state: ReduxStateType) => {
  return state.queries;
};

export const selectQueries = (state: ReduxStateType) => {
  return state.queries.queries;
};

export const selectLastMessage = (query: ReduxQueryType) => {
  return query.messages[query.messages.length - 1];
};

export const selectQuery = (state: ReduxStateType, queryId: number) => {
  return state.queries.queryMap[queryId];
}

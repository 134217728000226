import React, {useState} from 'react';
import {TableBody, TableCell, TableRow} from '@mui/material';
import {Link} from 'react-router-dom';

import {TAX_FILING_STATUS, themmeColor} from 'src/constants/constants';
import {getCpaCenterV2Step} from 'src/CpaCenterList/components/cpaList.utils';
import useIsCpaCenterV2 from 'src/CpaCenterV2/hooks/useIsCpaCenterV2';

const CpaListTableRow = ({
  record,
  tableData,
  getRedirectRoute,
}: {
  record: any;
  tableData: any;
  getRedirectRoute: () => string;
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TableRow
      component={Link}
      to={getRedirectRoute()}
      style={{
        backgroundColor: isFocused ? themmeColor.focusedBlue : undefined,
        textDecoration: 'none',
      }}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}>
      {tableData.map((cell) => (
        <TableCell align={'left'}>
          <div>{cell.render(record)}</div>
        </TableCell>
      ))}
    </TableRow>
  );
};

const ListTableBody = ({
  recordsArray,
  tableData,
  getRedirectRoute,
  flyUserIdFieldName,
  cpaReturnStatusFieldName,
  isTaxFilingTable = false,
}: {
  recordsArray: Array<any>;
  tableData: any;
  getRedirectRoute: (params: {
    flyfin_user_id: number;
    query_id?: number;
  }) => string;
  flyUserIdFieldName: string;
  cpaReturnStatusFieldName?: string;
  isTaxFilingTable?: boolean;
}) => {
  const {isCpaCenterV2} = useIsCpaCenterV2();
  return (
    <TableBody>
      {recordsArray.map((record) => (
        <CpaListTableRow
          record={record}
          tableData={tableData}
          getRedirectRoute={() => {
            const flyUserId = record[flyUserIdFieldName];

            const params = {
              flyfin_user_id: flyUserId,
              query_id: record?.id,
            };

            let route = getRedirectRoute(params);
            if (
              isCpaCenterV2 &&
              isTaxFilingTable &&
              cpaReturnStatusFieldName
            ) {
              const cpaReturnStatus = record[
                cpaReturnStatusFieldName
              ] as TAX_FILING_STATUS;
              route += `/${getCpaCenterV2Step(cpaReturnStatus)}`;
            }
            return route;
          }}
        />
      ))}
    </TableBody>
  );
};

export default ListTableBody;

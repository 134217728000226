import React from 'react';

const DeleteIcon = ({height = 24, width = 24}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none">
      <path
        d="M9.8418 9.97747V17.4197M14.1592 9.97747V17.4197M4 6.20763H5.5M8 6.20763H16M8 6.20763V5.13842C8 3.9574 8.89543 3 10 3H14C15.1046 3 16 3.9574 16 5.13842V6.20763M8 6.20763H5.5M16 6.20763H18.5M20 6.20763H18.5M18.5 6.20763V18.8616C18.5 20.0426 17.6046 21 16.5 21H7.5C6.39543 21 5.5 20.0426 5.5 18.8616V6.20763"
        stroke="#0D0F0F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;

import React, {useState} from 'react';
import {Typography} from '@mui/material';
import Box from 'src/DesignSystem/Box/Box';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import UploadDocumentIcon from 'src/icons/UploadDocumentIcon';
import UploadReturn from '../AddReturn/components/UploadReturn';
import {COMMON_COMPONENTS_ID} from 'src/constants/constants';
import useTaxReturnCurrentStep from 'src/CpaCenterV2/hooks/useTaxReturnCurrentStep';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';

interface TaxReturnSectionHeaderProps {}

const TaxReturnSectionHeader = ({}: TaxReturnSectionHeaderProps) => {
  const [isReuploadPopup, setIsReuploadPopup] = useState(false);
  const {taxReturnSection} = useTaxReturnCurrentStep();

  const openReuploadPopup = () => {
    setIsReuploadPopup(true);
  };
  const closeReuploadPopup = () => {
    setIsReuploadPopup(false);
  };

  const getTitle = () => {
    switch (taxReturnSection) {
      case TaxReturnStatus.ADD_RETURN:
        return 'Tax return - Add return';
      case TaxReturnStatus.REVIEW_SUMMARY:
        return 'Tax return - Review summary';
      case TaxReturnStatus.CPA_REVIEW:
        return 'Tax return - CPA review';
      case TaxReturnStatus.REVIEW_ESIGN:
        return 'Review and E-sign';
    }
  };

  const isActionButtonsAllowed = [
    TaxReturnStatus.REVIEW_SUMMARY,
    TaxReturnStatus.CPA_REVIEW,
    TaxReturnStatus.REVIEW_ESIGN,
  ].includes(taxReturnSection);
  return (
    <div
      id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_TAX_RETURN_SECTION_HEADER}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
      }}>
      <Typography style={{fontSize: 20, fontWeight: 800, marginBottom: 12}}>
        {getTitle()}
      </Typography>
      {isActionButtonsAllowed && (
        <div style={{display: 'flex'}}>
          <Box
            hoverEffect
            onClick={openReuploadPopup}
            style={{
              paddingInline: 12,
              paddingBlock: 8,
              alignItems: 'center',
              margin: 4,
            }}>
            <UploadDocumentIcon />
            <Typography style={{fontSize: 13, fontWeight: 600, marginLeft: 4}}>
              Re-upload
            </Typography>
          </Box>
        </div>
      )}
      <PopUp
        isOpen={isReuploadPopup}
        onClose={closeReuploadPopup}
        style={{width: 600, height: 400}}>
        <UploadReturn onDone={closeReuploadPopup} />
      </PopUp>
    </div>
  );
};

export default TaxReturnSectionHeader;

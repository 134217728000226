import {ReturnSummaryKeys} from 'src/constants/constants';

export enum ReturnSummaryInputType {
  TEXT_INPUT = 'TEXT_INPUT',
  DROPDOWN = 'DROWPDOWN',
}

type ReturnSummaryTextInputProps = {
  type: ReturnSummaryInputType.TEXT_INPUT;
  key: ReturnSummaryKeys;
  value: any;
  valueMandatory?: boolean;
};

type ReturnSummaryDropdownInputProps = {
  type: ReturnSummaryInputType.DROPDOWN;
  key: ReturnSummaryKeys | null;
  value: any;
  options: ReturnSummaryKeys[];
  valueMandatory?: boolean;
};

export type ReturnSummaryInputFieldProps =
  | ReturnSummaryTextInputProps
  | ReturnSummaryDropdownInputProps;

import React, {useEffect} from 'react';
import {Typography} from '@mui/material';
import ReturnStatusCard from './components/ReturnStatusCard';
import EfilePaymentDetailsDrawer from './components/EfilePaymentDetailsDrawer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {useDispatch, useSelector} from 'react-redux';
import useCurrentUserId from '../hooks/useCurrentUserId';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import Skeleton from 'src/icons/Skeleton';
import {fetchEfileLogs} from 'src/store/efileLogs/efileLogs.actions';
import {fetchBankDetails} from 'src/store/bankDetails/bankDetails.actions';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const TaxReturns = () => {
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();

  const {taxReturns, loaded} = useSelector(selectTaxReturnsReducer);

  useEffect(() => {
    dispatch(fetchTaxReturns(userId));
    dispatch(fetchEfileLogs(userId));
    dispatch(fetchBankDetails(userId));
  }, [activeYear]);

  return (
    <>
      <div style={{paddingLeft: 4, overflowY: 'auto'}}>
        <Typography style={{fontSize: 20, fontWeight: 800, marginBlock: 12}}>
          Return review & E-filing
        </Typography>
        {loaded ? (
          taxReturns.map((taxReturn) => (
            <ReturnStatusCard taxReturn={taxReturn} />
          ))
        ) : (
          <>
            <Skeleton width={'100%'} height={100} />
            <Skeleton width={'100%'} height={100} />
            <Skeleton width={'100%'} height={100} />
          </>
        )}
      </div>
      <EfilePaymentDetailsDrawer />
    </>
  );
};

export default TaxReturns;

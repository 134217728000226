import {DOCUMENT_TYPES, TaxProfileSubsection} from 'src/constants/constants';
import {DerivedQueryType} from 'src/store/queries/queries.reducer';
export enum QueryFilterKeys {
  docType = 'docType',
  subsection = 'subsection',
  highValueDeduction = 'highValueDeduction',
  userTaxProfile = 'userTaxProfile',
  general = 'general',
  reviewEsign = 'reviewEsign',
}

export type QueryFilterType =
  | {
      [QueryFilterKeys.docType]: DOCUMENT_TYPES;
    }
  | {
      [QueryFilterKeys.subsection]: TaxProfileSubsection;
    }
  | {
      [QueryFilterKeys.highValueDeduction]: DerivedQueryType.HIGH_VALUE_DEDUCTION;
    }
  | {
      [QueryFilterKeys.userTaxProfile]: DerivedQueryType.TAX_PROFILE;
    }
  | {
      [QueryFilterKeys.general]: DerivedQueryType.GENERAL;
    }
  | {
      [QueryFilterKeys.reviewEsign]: DerivedQueryType.REVIEW_ESIGN;
    };
